














































































import Button from "@/components/Button.vue";
import BleDeviceHelper from "@/utils/BleDeviceHelper";
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class CloseInstructions extends Vue {

	public currentStep:number = 1;
	public totalSteps:number = 13;
	public doorLocked:boolean = false;

	public getNavBtClasses(base:string, enabled:boolean):string[] {
		let res = [base];
		if(!enabled) res.push("hide");
		return res;
	}

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public nextStep():void {
		this.currentStep ++;
	}

	public prevStep():void {
		this.currentStep --;
	}

	public toggleCloseBox():void {
		this.doorLocked = !this.doorLocked;
		if(this.doorLocked) {
			BleDeviceHelper.instance.closeBox();
		}else{
			BleDeviceHelper.instance.openSecretCompartment();
		}
	}

}
