

























import AlienSymbol from '@/components/AlienSymbol.vue';
import CirclesRendererCanvas from '@/components/CirclesRendererCanvas.vue';
import WobbleButton from '@/components/WobbleButton.vue';
import Api from '@/utils/Api';
import BleDeviceHelper, { BleEvent, SoundEffect } from '@/utils/BleDeviceHelper';
import Recorder from '@/utils/Recorder';
import SHA512 from '@/utils/SHA512';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import AbstractBLEView from '../AbstractBLEView';

@Component({
	components:{
		AlienSymbol,
		WobbleButton,
		CirclesRendererCanvas,
	}
})
export default class NameEnigma extends AbstractBLEView {

	public circlesCount:number = 32;
	public spectrumData:{a:number, s:number}[] = [];
	public spectrumDataSave:{a:number, s:number}[] = [];
	
	public sounds:__WebpackModuleApi.RequireContext = null;
	public clicks:number[] = [];
	public symbolsDist:{v:number}[] = [];
	public audios:HTMLAudioElement[] = [];
	public recording:boolean = false;
	public replayPercent:number = 1;
	public highlightFaded:any = null;

	private FFT_SIZE = 2048;
	private inc = 0;
	private ready = false;
	private disposed = false;
	private spelling = false;
	private spellingFrameIndex = 0;
	private outroAnimation = false;
	private outroAnimationStep2 = false;
	private answerSound:HTMLAudioElement;
	private playingAudio:HTMLAudioElement;
	private audioSave:HTMLAudioElement = null;
	private stream:MediaStream;
	private audioRecorder:Recorder;
	private audioContext:AudioContext;
	private audioInput:MediaStreamAudioSourceNode;
	private inputPoint:GainNode;
	private analyserNode:AnalyserNode;
	private mouseUpHandler:any;

	public getSymbolStyles(index:number):any {
		let dist = this.symbolsDist[index-1].v;
		let angle = (index/6) * Math.PI * 2;
		let px = Math.cos(angle) * dist;
		let py = Math.sin(angle) * dist;
		return {
			transform:"translate(calc(-50% + "+px+"px), calc(-50% + "+py+"px))",
		}
	}

	public async mounted():Promise<void> {
		this.highlightFaded = Utils.getLessVars().mainColor_normal.replace(")", ", .25)").replace("(", "a(");
		
		super.mounted();
		let g = <HTMLElement>this.$refs.gradient;
		let b = g.getBoundingClientRect();
		g.style.height = b.width+"px";

		this.sounds = require.context("@/assets/sounds/syllables");
		let spells = ["./4.mp3","./5.mp3","./6.mp3","./1.mp3","./8.mp3","./9.mp3"];
		for (let i = 0; i < spells.length; i++) {
			const element = spells[i];
			let url = this.sounds(element);
			let a = new Audio(url);
			// a.load(url);
			this.audios.push(a);
			this.symbolsDist.push({v:0});
			gsap.to(this.symbolsDist[i], 5, {v:130, delay:(i+1)*.5, ease:"back.out", onComplete:_=> {
				gsap.to(this.symbolsDist[i], Math.random() + 3.5, {v:120, repeat:-1, yoyo:true, ease:"sine.inOut"});
			}});
		}
		
		this.ready = true;
		this.onRenderFrame();

		this.mouseUpHandler = (e) => this.stopRecording();
		document.addEventListener("touchend", this.mouseUpHandler);
		document.addEventListener("mouseup", this.mouseUpHandler);
	}

	public beforeDestroy():void {
		this.disposed = true;

		this.releaseMic();
		document.removeEventListener("touchend", this.mouseUpHandler);
		document.removeEventListener("mouseup", this.mouseUpHandler);
	}

	protected onDeviceConnect(e:BleEvent = null):void {
		BleDeviceHelper.instance.playMusic(SoundEffect.NAME, true);
	}

	public spell(index:number):void {
		this.audios[index-1].play();
		this.clicks.push(index);
		this.spelling = true;
		this.spellingFrameIndex = 0;
		if(this.playingAudio) {
			this.playingAudio.pause();
			(<WobbleButton>this.$refs.wobbleBt).stopWobble();
		}
		
		// Generate answers hashes
		// console.log(SHA512.encode([5,2,1,3].join()));
		// console.log(SHA512.encode([3,1,2,5].join()));

		if(this.clicks.length > 4) this.clicks.shift();
		let answer = this.clicks.join();

		if(SHA512.encode(answer) == "b34531516ed1eb75918b9f5c80e5989829e9d49380ceafe2267c9a89ca3cdae7c25b3128789c6f111070323721e02b87408d29ddb786ea79281179bc30b8a420" ||
		SHA512.encode(answer) == "0ac7fc19e82a483a99c04ed0a6c89e5a3cabb0b3f97916659aa23bb2cba67d1995735cd711ac0d422a8814c0db3ca2e3e02da5457256df988432d3098b83baee") {
			this.outro();
		}
	}


	public startRecording():void {
		this.spelling = false;
		this.recording = true;
		this.initAudio();
		(<WobbleButton>this.$refs.wobbleBt).startWobble();
	}

	public stopRecording():void {
		if(!this.recording) return;
		this.recording = false;
		if(this.audioRecorder) {
			this.audioRecorder.stop();
			this.saveRecording();
		}else{
			(<WobbleButton>this.$refs.wobbleBt).stopWobble();
		}
	}
4
	public saveRecording():void {
		this.audioRecorder.exportWAV((blob) => {
			this.spectrumDataSave = JSON.parse(JSON.stringify(this.spectrumData));
			let url = (window.URL || window.webkitURL).createObjectURL(blob);
			this.playingAudio = new Audio(url);
			// this.playingAudio.volume = 1;
			this.playingAudio.play();
			this.playingAudio.addEventListener("ended", (e)=>{
				if(!this.recording) {//Ignore, the user clicked start again inbetween
					this.audioSave = this.playingAudio;
					(<WobbleButton>this.$refs.wobbleBt).stopWobble();
				}
			})
			this.clearRecording();
		}, "audio/wav", true, true, 1);
	}

	private initAudio():void {
		if (!navigator.getUserMedia) {
			//@ts-ignore
			navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia ||navigator.mozGetUserMedia || navigator.msGetUserMedia;
		}

		let constraints:any = {
			"audio": {
				"mandatory": {
				},
				"optional": [
					{"googEchoCancellation": "false"},
					{"googAutoGainControl": "false"},
					{"googNoiseSuppression": "false"},
					{"googHighpassFilter": "false"}
				]
			},
		}
		// constraints = {audio:true, video:false};


		if(navigator.mediaDevices) {
			navigator.mediaDevices.getUserMedia(constraints)
			.then( stream => {
				this.onStream(stream);
			})
			.catch(error => {
				this.$store.dispatch("setMicAccessRefusedReason", error.name);
			});
		} else if (navigator.getUserMedia) {
			navigator.getUserMedia(constraints, 
			(stream) => { this.onStream(stream); },
			(error) => { this.$store.dispatch("setMicAccessRefusedReason", error.name); });

		} else {
			this.$store.dispatch("setMicAccessRefusedReason", "unknown");
		}
	}

	private onStream(stream:MediaStream):void {
		if(!this.recording) return;//User released button
		this.stream = stream;
		this.spectrumData = [];
		this.initRecorder();
		this.audioRecorder.start();
		let a = Math.PI * 2;
		for (let i = 0; i < 5; i++) {
			this.spectrumData.push({a, s:-a/2 - Math.PI/2});// * (i+1)/5)
		}
	}

	private initRecorder():void {
		//@ts-ignore
		let ac = window.AudioContext || window.webkitAudioContext;//Fuck you apple for still prefixing shits...
		this.audioContext = new ac();
		this.audioInput = this.audioContext.createMediaStreamSource(this.stream);
		this.inputPoint = this.audioContext.createGain();
		this.inputPoint.gain.value = 1;
		this.analyserNode = this.audioContext.createAnalyser();
		this.analyserNode.fftSize = this.FFT_SIZE;
		this.inputPoint.connect( this.analyserNode );
		this.audioInput.connect( this.inputPoint );
		this.audioRecorder = new Recorder( this.inputPoint );
	}

	public clearRecording():void {
		if(this.audioContext) {
			this.audioContext.close().then(_=> {
				this.releaseMic();
			});
		}
	}

	private releaseMic():void {
		if(this.inputPoint) {
			this.inputPoint.disconnect();
			this.audioInput.disconnect();
		}
		if(this.audioRecorder) {
			this.audioRecorder.dispose();
		}

		if(this.stream) {
			//This is necessary to "free" the mic
			for (let i = 0; i < this.stream.getTracks().length; i++) {
				this.stream.getTracks()[i].stop();
			}
		}

		this.stream = null;
		this.inputPoint = null;
		this.audioInput = null;
		this.audioContext = null;
		this.audioRecorder = null;
	}

	private onRenderFrame():void {
		if(this.disposed) return;
		requestAnimationFrame(_=> this.onRenderFrame());

		this.inc ++;
		if(this.inc%6 == 0) return;//Slowdown 

		if(this.spelling) {
			let a = Math.random() * (Math.PI*.25) + Math.PI * .25;
			if(++this.spellingFrameIndex > 7) a = 0;
			this.spectrumData.unshift({a, s:-a/2 - Math.PI/2});
			if(this.spellingFrameIndex > 40) {
				this.spelling = false;
			}
			return;
		}


		if(this.outroAnimation || this.outroAnimationStep2) {
			let a = (this.outroAnimationStep2? 0 : Math.cos(this.inc%20/20 * Math.PI * 2) + 1)/2 * (Math.PI*.9);
			this.spectrumData.unshift({a, s:-a/2 - Math.PI/2});
			if(this.spectrumData.length > this.circlesCount) {
				this.spectrumData.pop();
			}
			return;
		}

		if(this.playingAudio && !this.playingAudio.paused && !this.recording) {
			this.spectrumData.shift();
		}
		
		if(!this.audioRecorder || !this.audioRecorder.isRecording) return;

        var freqByteData = new Uint8Array(this.analyserNode.frequencyBinCount);
		this.analyserNode.getByteTimeDomainData(freqByteData);
		let max = 0;
		for (let i = 0; i < freqByteData.length; i++) {
			max = Math.max(max, freqByteData[i]);
		}
		let a = Math.min(180, (max-128)/128 * 360)/360*Math.PI*2;
		this.spectrumData.unshift({a, s:-a/2-Math.PI/2});
		// if(this.spectrumData.length > this.circlesCount) this.spectrumData.pop();
	}

	private async replayAudio():Promise<void> {
		this.spectrumData = JSON.parse(JSON.stringify(this.spectrumDataSave));
		this.audioSave.currentTime = 0;
		this.audioSave.play();
		(<WobbleButton>this.$refs.wobbleBt).startWobble();
		(<WobbleButton>this.$refs.replayBt).startWobble();
		await Utils.promisedTimeout(100);
		(<WobbleButton>this.$refs.replayBt).stopWobble();
	}

	private async outro():Promise<void> {
		this.outroAnimation = true;
		let symbols = <Vue[]>this.$refs.symbols;
		for (let i = 0; i < this.symbolsDist.length; i++) {
			gsap.killTweensOf(this.symbolsDist[i]);
			(<AlienSymbol>this.$refs.symbols[i]).stop();
			gsap.to(this.symbolsDist[i], 2, {v:Math.max(document.body.clientWidth, document.body.clientHeight), ease:"back.in(.85)"});
		}
		gsap.to((<Vue>this.$refs.wobbleBt).$el, 1, {scale:0, ease:"back.in(3.85)"});

		await Utils.promisedTimeout(1000);
		this.outroAnimation = false;
		this.outroAnimationStep2 = true;
		gsap.to(this.$el, 1, {opacity:0});

		await Utils.promisedTimeout(1000);
		BleDeviceHelper.instance.stopMusic();
		await Utils.saveTimestamp("nameFound");
		this.$router.push({name:"game:d", params:{step:"alienNameOk", lang:this.$store.state.lang}});
	}

}
