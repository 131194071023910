






import { Component, Inject, Model, Prop, Vue, Watch, Provide } from 'vue-property-decorator'
import gsap from 'gsap';

@Component
export default class AlertView extends Vue {

	public message:string = "";
	public timeout:number;

	public mounted():void {
		this.onWatchAlert();
	}

	@Watch("$store.state.alert")
	public async onWatchAlert():Promise<void> {
		let mess = this.$store.state.alert;
		if(mess && mess.length > 0) {
			this.message = mess;
			await this.$nextTick();
			this.$el.removeAttribute("style");
			gsap.killTweensOf(this.$el);
			gsap.from(this.$el, {duration:.3, height:0, paddingTop:0, paddingBottom:0, ease:"back.out"});
			this.timeout = setTimeout(_=> this.close(), this.message.length*80);
		}else if(this.message) {
			gsap.to(this.$el, {duration:.3, height:0, paddingTop:0, paddingBottom:0, ease:"back.in", onComplete:()=> {
				this.message = null;
			}});
		}
	}

	public close():void {
		clearTimeout(this.timeout);
		this.$store.state.alert = null;
	}
}
