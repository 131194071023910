







import Utils from '@/utils/Utils';
import gsap, { SplitText } from 'gsap/all';
import { log } from 'three';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import AlienLetterWritter from '@/utils/AlienLetterWritter';

@Component({
	components:{
	}
})
export default class MessageWritter extends Vue {

	@Prop({default:""})
	public message:string;

	@Prop({default:false})
	public isAlien:boolean;

	@Prop()
	public effect:string;

	@Prop({default:1})
	public timeScale:number;

	public message_local:string = null;
	public alwList:AlienLetterWritter[] = null
	public finalMessage:string = "";
	public finalMessageLength:number = 0;
	private delay:number;//Do not init value or there will be a strange infinite loop
	private audiosHuman:HTMLAudioElement[] = [];
	private audiosAlien:HTMLAudioElement[] = [];
	private splitter:any = null

	public getMessageClasses():string[] {
		let res = ["messagewritter"];
		if(this.isAlien) res.push("alien");
		return res;
	}


	public randomizeEffect(effect:string, index:number):string {
		let res = "";
		//make effect stronger and stronger
		if(Math.random() > (1-index/this.finalMessageLength)) {
			res = effect;
		}
		return res;
	}

	public mounted():void {
		this.delay = 0;

		//Preload elements
		let preloaders = [require.context("@/assets/sounds/typewrite_human"), require.context("@/assets/sounds/typewrite_alien")];
		for (let i = 0; i < preloaders.length; i++) {
			preloaders[i].keys().forEach(path => {
				//Add 2 occurences of each alien sound because there are fewer
				for (let j = 0; j < (i==1? 1 : 2); j++) {
					let a = new Audio(preloaders[i](path));
					if(i==0) {
						this.audiosHuman.push(a);
					}else{
						this.audiosAlien.push(a);
					}
				}
			});
		}
		this.audiosHuman = Utils.shuffle(this.audiosHuman);
		this.audiosAlien = Utils.shuffle(this.audiosAlien);
		this.onMessageUpdate();
	}

	public beforeDestroy():void {
		
	}

	public skipAnimation():void {
		for (let i = 0; i < this.audiosHuman.length; i++) {
			this.audiosHuman[i].pause();
		}
		for (let i = 0; i < this.audiosAlien.length; i++) {
			this.audiosAlien[i].pause();
		}
		this.onLetterComplete(this.finalMessageLength);
	}

	public computeDelay(i:number, holder:ChildNode):number {
		this.delay += (!this.isAlien? .04 : .03) * this.timeScale;

		//if prev node is a <br> and if it's the first letter of the current word
		if(holder.parentElement
		&& holder.parentElement.previousSibling
		&& holder.parentElement.previousSibling.nodeName == "BR"
		&& holder.parentElement.firstChild == holder) {
			this.delay += .5 * this.timeScale;
		}else
		//Add delay if it's a dot not followed by another dot
		if(i > 2 && i < this.splitter.chars.length-1
		&& this.splitter.chars[i-1].innerText == "."
		&& this.splitter.chars[i].innerText != ".") {
			this.delay += .4 * this.timeScale;
		}else
		//Add delay if it's a coma
		if(i > 2 && i < this.splitter.chars.length-1
		&& this.splitter.chars[i-1].innerText == ",") {
			this.delay += .25 * this.timeScale;
		}

		return this.delay;
	}

	public restart():void {
		this.onMessageUpdate();
	}


	/**
	 * Called when a letter is starting to be displayed
	 */
	public onStartLetter(index:number, effect:string):void {
		this.$emit("onLetter");
		// if(index%(this.isAlien?20:1)!=0) return;

		let alienSound = this.isAlien || effect=="glitch";
		let src = alienSound? this.audiosAlien : this.audiosHuman;

		let playingCount = 0;
		for (let i = 0; i < src.length; i++) {
			if(!src[i].paused) playingCount ++;
		}

		//Play max 5 sounds simultaneously
		if(playingCount > 5) {
			return;
		}
		try {
			let item = src[ Math.floor(Math.random()*src.length) ];
			if(item) {
				item.volume = alienSound? 1 : .5;
				item.play().catch((e:Error)=> {
					/*ignore*/
				});
			}
		}catch(e) { /*ignore*/ }
	}

	public onLetterChange(index:number, effect:string):void {
		this.onStartLetter(index, effect);
	}


	/**
	 * When animation completes, replace all divs by actual
	 * letters, to make copy/past working
	 */
	public onLetterComplete(index:number):void {
		if(index >= this.finalMessageLength) {
			let wasComplete = this.finalMessage != null && this.finalMessage.length > 0;
			if( this.effect != "glitch") {
				this.finalMessage = this.message.replace(/\n|\r/gi, "<br>")
				.replace(/~(.*?)~/gi, "<span class='bold'>$1</span>")
				.replace(/¶(.*?)¶/gi, "<span class='font'>$1</span>")
				.replace(/([a-zÀ-ž]+-[a-zÀ-ž]+)/gi, "<span class='nobr'>$1</span>");
			}
			if(!wasComplete) {
				this.$emit("complete");
			}
			this.killAllTweens();
		}
	}

	@Watch("message")
	private async onMessageUpdate():Promise<void> {
		if(!this.message) {
			this.message_local = "";
			this.finalMessage = "";
			return;
		}
		
		let holder = (<HTMLDivElement>this.$refs.message);
		this.delay = 0;
		this.finalMessage = "";
		this.finalMessageLength = 0;
		this.killAllTweens();
		holder.innerHTML = "";
		this.message_local = this.message.replace(/\n|\r/gi, "<br>");
		
		await this.$nextTick();

		//GSAP splittext method. 
		this.splitter = new SplitText(this.$refs.message, {type:"words,chars"});
		this.finalMessageLength = this.splitter.chars.length-1;
		let isBold:boolean = false;
		let isFont:boolean = false;
		this.alwList = [];
		
		for (let i = 0; i < this.splitter.chars.length; i++) {
			const c:HTMLDivElement = this.splitter.chars[i];
			const l = c.innerText;
			if(l == "~") {
				isBold = !isBold;
				c.parentNode.removeChild(c);
			}else if(l == "¶") {
				isFont = !isFont;
				c.parentNode.removeChild(c);
			}
			if(isBold) c.classList.add("bold");
			if(isFont) c.classList.add("font");

			let alw = new AlienLetterWritter(i, c, this.isAlien, this.computeDelay(i, c), this.randomizeEffect(this.effect, i), this.timeScale);
			alw.addEventListener("start", (e)=> this.onStartLetter(e.index, e.effect));
			alw.addEventListener("change", (e)=> this.onLetterChange(e.index, e.effect));
			alw.addEventListener("complete", (e)=> this.onLetterComplete(e.index));
			this.alwList.push(alw);
		}
	}

	private killAllTweens():void {
		if(!this.alwList) return;
		for (let i = 0; i < this.alwList.length; i++) {
			this.alwList[i].killTweens();
		}
	}

}
