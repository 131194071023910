










import Button from '@/components/Button.vue';
import Api from '@/utils/Api';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class AdminAuth extends Vue {

	public password:string = "";
	public loading:boolean = false;

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public async submitForm():Promise<void> {
		if(this.loading) return;
		this.loading = true;
		let res, alertDone:boolean;
		try {
			res = await Api.post("auth", {password:this.password});
		}catch(e) {
			res = false;
			alertDone = e.alertDone;
		}
		if(!res) {
			this.loading = false;
			if(!alertDone) this.$store.dispatch("alert", this.$t('errors.invalidCredentials'))
		}else{
			this.$store.dispatch("authenticate", res.token);
			console.log(this.$router.resolve({name:"admin"}));
			//Force page reload to get proper labels
			window.location.href = this.$router.resolve({name:"admin"}).href;
		}
	}

}
