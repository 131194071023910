
























import Button from '@/components/Button.vue';
import AbstractBLEView from '@/views/AbstractBLEView';
import IncrementableNumber from '@/components/IncrementableNumber.vue';
import BleDeviceHelper, { BleEvent, SoundEffect } from '@/utils/BleDeviceHelper';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Api from '@/utils/Api';
import Dialog from './Dialog.vue';
import Utils from '@/utils/Utils';
import CirclesRendererCanvas from '@/components/CirclesRendererCanvas.vue';
import SHA512 from '@/utils/SHA512';

@Component({
	components:{
		Button,
		Dialog,
		CirclesRendererCanvas,
		IncrementableNumber,
	}
})
export default class Frequency extends AbstractBLEView {


	public v1:number = 0;
	public v2:number = 0;
	public v3:number = 0;
	public v4:number = 0;
	public circlesCount:number = 32;
	public spectrumData:{a:number, s:number}[] = [];
	public inc:number = 0;
	public perturbationInc:number = 0;
	public message:any = {u:"", t:""};
	public frequencyFound:boolean = false;
	public showMessage:boolean = false;
	public disposed:boolean;
	public highlightColor:string = null;

	public get frequency():number {
		return this.v4 + this.v3*10 + this.v2*100 + this.v1*1000;
	}

	public async mounted():Promise<void> {
		super.mounted();
		
		this.highlightColor = Utils.getLessVars().mainColor_normal.replace(")", ", .25)").replace("(", "a(");
		for (let i = 0; i < this.circlesCount; i++) {
			this.spectrumData[i] = {a:0, s:0}
		}
		this.renderAnim();
	}

	public beforeDestroy():void {
		this.disposed = true;
		BleDeviceHelper.instance.stopMusic();
	}

	@Watch("frequency")
	private async onFrequencyChange():Promise<void> {
		// Hash answer
		// console.log(SHA512.encode("1421".toString()));
		if(SHA512.encode(this.frequency.toString()) == "5dd8998178ff034f7d280b8bdea1ceefc2c5d84eae884bac7931e2fb99fbc9ef6c00ee49802ce9d8dce41909ec362727f61785b1fc18a35ecb702aa9989d3216") {
			await Utils.promisedTimeout(100);//Give time to release to avoid skipping dialog animation
			BleDeviceHelper.instance.playMusic(SoundEffect.GLITCHING, true);
			this.message = this.$t("game.dialogs.frequencyOK");
			this.showMessage = true;
			this.frequencyFound = true;
			this.inc = 0;
			let res;
			await Utils.saveTimestamp("frequencyFound");
		}
	}

	protected onDeviceConnect(e:BleEvent = null):void {
		BleDeviceHelper.instance.playMusic(SoundEffect.FREQUENCY_AMBIANT, true);
		setTimeout(_=> {
			if(this.frequencyFound) return;
			this.showMessage = true;
			this.message = this.$t("game.dialogs.frequencyHelp");
		}, 9000);
		this.startSound();
	}

	private startSound():void {
		setTimeout(_=> {
			this.burst();
		}, 7950);

		setTimeout(_=> {
			this.burst();
		}, 20500);

		setTimeout(_=> {
			this.startSound();
		},25050)
	}

	private async burst():Promise<void> {
		this.doBurst();
		await Utils.promisedTimeout(890);
		this.doBurst();
		await Utils.promisedTimeout(400);
		this.doBurst();
		await Utils.promisedTimeout(400);
		this.doBurst();
		await Utils.promisedTimeout(400);
		this.doBurst();
		await Utils.promisedTimeout(910);
		this.doBurst();
		await Utils.promisedTimeout(410);
		this.doBurst();
		await Utils.promisedTimeout(880);
		this.doBurst();
	}

	private async doBurst():Promise<any> {
		if(this.frequencyFound) return;

		for (let i = 0; i < this.circlesCount; i++) {
			let r = i/this.circlesCount;
			let a = ( Math.pow(Math.sin(2*r+i/6), 4) + 1 )/2 * Math.PI*.8 * (1-i/this.circlesCount);
			let s = -a/2 - Math.PI/2;
			this.spectrumData[i] = {a, s};
		}
	}

	private renderAnim():void {
		if(this.disposed) return;
		requestAnimationFrame(_=> this.renderAnim());
		
		this.inc ++;
		if(this.inc%2 == 0) return;//Slowdown
		
		for (let i = 0; i < this.circlesCount; i++) {
			let r = i/this.circlesCount;
			let rotationOffset = 0;
			if(this.frequencyFound) {
				let v = Math.round(this.inc%900/5 - i);
				let mod = (10*((Math.cos(this.inc%900*.025)+1)/2)+10);
				let a = v%mod<4? Math.PI * 2 * (1-r) : 0;
				this.spectrumData[i].a += (a - this.spectrumData[i].a)*.1;
				// rotationOffset = Math.sin(this.inc*.001 + v*.01)*Math.PI*2;
			}else{
				let sum = (this.v1+this.v2+this.v3+this.v4);
				let a = ( Math.pow(Math.sin(this.v3*r+i/this.v2+this.v1+this.v4), this.v2) + 1 )/2 * Math.PI*(sum/10) * (1-r) * Math.min(1, sum/8);
				this.spectrumData[i].a += (a - this.spectrumData[i].a)*.15;
			}
			
			this.perturbationInc += .05;
			this.spectrumData[i].a += (Math.cos(this.perturbationInc + i)+1)/2 * 2/360 * Math.PI * 2 * (1-r);

			let s = -this.spectrumData[i].a/2 - Math.PI/2;
			this.spectrumData[i].s = s + rotationOffset;
		}
	}

	private async clickNext():Promise<void> {
		await Utils.promisedTimeout(100);//Give time to release to avoid skipping dialog animation
		if(this.frequencyFound) {
			let lastMess = <any>this.$t("game.dialogs.frequencyOKGlitch");
			if(this.message.t == lastMess.t) {
				this.$router.push({name:"game:d", params:{step:'alienGreet', lang:this.$store.state.lang}})
			}else{
				this.message = lastMess;
			}
		}else{
			this.showMessage = false;
		}
	}

}
