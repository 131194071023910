






import Utils from '@/utils/Utils';
import { random } from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class WobbleButton extends Vue {

	@Prop({default:200})
	private size:number;

	@Prop({default:50})
	private padding:number;
	
	private wobbling:boolean = false;
	private disposed:boolean;
	private cnv:HTMLCanvasElement;
	private ctx:CanvasRenderingContext2D;
	private coeffs:number[] = [];
	private attractorsPos:number[] = [];
	private attractorsSpeed:number[] = [];
	private attractorsCoeffs:number[] = [];
	private attractorsDist:number[] = [];

	public get styles():any {
		return {width:this.size+"px", height:this.size+"px"};
	}

	public mounted():void {
		this.cnv = <HTMLCanvasElement>this.$refs.canvas;
		this.ctx = this.cnv.getContext("2d");

		let b = this.cnv.getBoundingClientRect();
		// this.size = this.cnv.width + this.padding * 2;
		this.cnv.width = this.size;
		this.cnv.height = this.size;

		for (let i = 0; i < 12; i++) {
			this.attractorsPos.push(Math.random()*Math.PI*2);
			this.attractorsDist.push(45/360 * Math.PI * 2);
			this.attractorsCoeffs.push(1);
			this.attractorsSpeed.push(1);
		}
		let last = this.attractorsPos.length - 1;
		let last1 = this.attractorsPos.length - 2;
		this.attractorsDist[last] = 
		this.attractorsDist[last1] = 45/360 * Math.PI * 2;

		this.stopWobble();//Init speeds and coeffs to default

		this.renderFrame();
	}

	public beforeDestroy():void {
		this.disposed = true;
	}

	public startWobble():void {
		this.wobbling = true;
		let dirSign = 1;
		for (let i = 0; i < this.attractorsSpeed.length - 2; i++) {
			this.attractorsCoeffs[i] = .01 * Math.random() + .01;
			this.attractorsSpeed[i] = (Math.random()*.1 + .05)/360 * Math.PI * 2 * dirSign;
			if(Math.random() > .5) this.attractorsCoeffs[i] = -this.attractorsCoeffs[i];
			// if(Math.random() > .5) this.attractorsSpeed[i] = -this.attractorsSpeed[i];
			dirSign = -dirSign;
		}
		
	}

	public stopWobble():void {
		this.wobbling = false;
		let dirSign = 1;
		for (let i = 0; i < this.attractorsSpeed.length - 2; i++) {
			this.attractorsCoeffs[i] = .0075;// * Math.random();
			this.attractorsSpeed[i] = (Math.random()*.025 + .01)/360 * Math.PI * 2 * dirSign;
			if(Math.random() > .5) this.attractorsCoeffs[i] = -this.attractorsCoeffs[i];
			// if(Math.random() > .5) this.attractorsSpeed[i] = -this.attractorsSpeed[i];
			dirSign = -dirSign;
		}

		let last = this.attractorsPos.length - 1;
		let last1 = this.attractorsPos.length - 2;
		this.attractorsPos[last] = -Math.PI/2;
		this.attractorsCoeffs[last] = 0;
		this.attractorsSpeed[last] = 0;
		this.attractorsPos[last1] = Math.PI/2;
		this.attractorsCoeffs[last1] = 0;
		this.attractorsSpeed[last1] = 0;
	}

	private renderFrame():void {
		if(this.disposed) return;
		requestAnimationFrame(_=> this.renderFrame());

		if(this.wobbling) {
			let last = this.attractorsPos.length - 1;
			let last1 = this.attractorsPos.length - 2;
			this.attractorsCoeffs[last] =
			this.attractorsCoeffs[last1] = (Math.random()-Math.random()*.7) * .2;
		}

		let sides = 35;
		this.ctx.clearRect(0,0,this.size,this.size);
		this.ctx.beginPath();
		this.ctx.fillStyle = Utils.getLessVars().mainColor_normal;
		for (let i = 0; i < sides; i++) {
			let angle = i/sides * Math.PI*2;
			if(this.coeffs.length <= i) this.coeffs[i] = 1;

			for (let j = 0; j < this.attractorsPos.length; j++) {
				let dist = Utils.angularDistance(angle, this.attractorsPos[j]);
				let attractDistMax = this.attractorsDist[j];
				if(dist < attractDistMax) {
					this.coeffs[i] += Math.pow((attractDistMax - dist)/attractDistMax, 1) * this.attractorsCoeffs[j];
				}
				this.attractorsPos[j] += this.attractorsSpeed[j];
			}

			let px = this.size*.5 + Math.cos(angle) * (this.size - this.padding*2) * .5 * this.coeffs[i];
			let py = this.size*.5 + Math.sin(angle) * (this.size - this.padding*2) * .5 * this.coeffs[i];
			if(i == 0) {
				this.ctx.moveTo(px, py);
			}else{
				this.ctx.lineTo(px, py);
			}

			this.coeffs[i] += (1 - this.coeffs[i]) * .1;
		}
		this.ctx.fill();
		this.ctx.closePath();
	}

}
