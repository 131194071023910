










import Utils from '@/utils/Utils';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class IncrementableNumber extends Vue {

	@Prop()
	public value:number;

	private pressed:boolean = false;
	private localValue:number = 0;
	private onMouseUp:any;

	public mounted():void {
		this.localValue = this.value;
		this.onMouseUp = (e)=> { this.pressed = false; }
		document.addEventListener("mouseup", this.onMouseUp);
		document.addEventListener("touchend", this.onMouseUp);
	}

	public beforeDestroy():void {
		document.removeEventListener("mouseup", this.onMouseUp);
		document.removeEventListener("touchend", this.onMouseUp);
	}

	@Watch("localValue")
	private async onChangeValue():Promise<void> {
		// this.localValue = Math.min(Math.max(this.localValue, 0), 9);
		if(this.localValue < 0) this.localValue = 10 + this.localValue;
		this.localValue = this.localValue%10;
		this.$emit("input", this.localValue);
	}

	private async increment(force:boolean = false):Promise<void> {
		if(!this.pressed && !force) return;
		this.pressed = true;
		this.localValue ++;
		await Utils.promisedTimeout(250);
		this.increment();
	}

	private async decrement(force:boolean = false):Promise<void> {
		if(!this.pressed && !force) return;
		this.pressed = true;
		this.localValue --;
		await Utils.promisedTimeout(250);
		this.decrement();
	}

}
