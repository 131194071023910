import { render, staticRenderFns } from "./Timeout.vue?vue&type=template&id=36edfd1c&scoped=true&"
import script from "./Timeout.vue?vue&type=script&lang=ts&"
export * from "./Timeout.vue?vue&type=script&lang=ts&"
import style0 from "./Timeout.vue?vue&type=style&index=0&id=36edfd1c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36edfd1c",
  null
  
)

export default component.exports