










import Config from '@/utils/Config';
import Utils from '@/utils/Utils';
import IGame from 'src_back/db/models/game/IGame';
import IUser from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import CirclesRenderer from './CirclesRenderer.vue';

@Component({
	components:{
		CirclesRenderer
	}
})
export default class MatesList extends Vue {

	@Prop({default:false})
	public vertical:boolean;
	
	public get game():IGame { return this.$store.state.game; }
	
	public get classes():string[] {
		let res = ["mateslist"];
		if(this.vertical !== false) res.push("vertical");
		return res;
	 }

	public getUserClasses(user:IUser):string[] {
		let res = ["user"];
		if((user.online || user._id == this.$store.state.user._id)
		&& Utils.isUserOnCollabPage(user, this.$router)) res.push("online");
		return res;
	}

	public getAvatarPath(user:IUser) {
		let port = Config.SERVER_PORT? ":"+Config.SERVER_PORT : "";
		return "//"+document.location.hostname+port+"/uploads/avatar/"+user._id+".jpg";
	}

	public isEveryoneOnline():boolean {
		let res = true;
		for (let i = 0; i < this.game.users.length; i++) {
			const u = this.game.users[i];
			if((u.online || u._id == this.$store.state.user._id) && Utils.isUserOnCollabPage(u, this.$router)) continue;
			res = false;
		}
		return res;
	}

	public mounted():void {
		
		
	}

	public beforeDestroy():void {
		
	}

}
