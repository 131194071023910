import BleDeviceHelper from '@/utils/BleDeviceHelper';
import SockController, { SOCK_ACTIONS } from '@/controller/SockController';
import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import IGAme from 'src_back/db/models/game/IGame';
import Vue from 'vue';
import Vuex from 'vuex';
import Store from './Store';
import {AES, enc} from "crypto-js";
import IUser from 'src_back/db/models/user/IUser';

Vue.use(Vuex)

let startPromise: Promise<any>;

export default new Vuex.Store({
	state: {
		initComplete: false,
		loggedin:false,
		session:null,
		tooltip: null,
		toffset: null,
		alert: null,
		lang: null,
		i18n: null,
		user:null,//User object for a game session, it has nothing to do with admin session
		game:null,//Current game session details
		timestampsToSave:null,
		micAccessRefusedReason:null,
		supportedLanguages:["fr", "en"],
		confirm:{
		  title:null,
		  description:null,
		  confirmCallback:null,
		  cancelCallback:null,
		  yesLabel:null,
		  noLabel:null,
		},
	},






	mutations: {

		initComplete(state) { state.initComplete = true; },

		alert(state, payload) { state.alert = payload; },

		setMicAccessRefusedReason(state, payload) { state.micAccessRefusedReason = payload; },

		openTooltip(state, payload) { state.tooltip = payload; },
		
		closeTooltip(state) { state.tooltip = null; },

		confirm(state, payload) { state.confirm = payload; },
		
		updateUser(state, payload) { state.user = payload; },

		startGame(state, payload) {
			state.user = payload.user;
			state.game = payload.game;
			if(Store) {
				Store.set("user", JSON.stringify(state.user));
				Store.set("game", JSON.stringify(state.game));
			}
			BleDeviceHelper.instance.initFallbackFromUser(state.user);
			SockController.instance.user = state.user;
			let mateIds = [Utils.getTeamMate()._id];
			SockController.instance.mates = mateIds;
		},

		setLanguageCode(state, payload) {
			state.lang = payload;
			// moment.locale(payload);
			if(state.i18n){
				state.i18n.locale = payload;
			}
		},
		
		authenticate(state, payload) {
			state.loggedin = true;
			state.session = payload;
			if(payload) {
				Store.set("session", payload);
			}else{
				Store.remove("session");
			}
		},
		
		logout(state, payload) {
			state.loggedin = false;
			state.session = null;
			Store.remove("session");
		},
		
		userJoined(state, payload:{uid:string}) {
			let g = <IGAme>state.game;
			for (let i = 0; i < g.users.length; i++) {
				const u = g.users[i];
				if(u._id == payload.uid) {
					Vue.set(u, "online", true);
				}
			}
		},
		
		userLeft(state, payload:{uid:string}) {
			let g = <IGAme>state.game;
			for (let i = 0; i < g.users.length; i++) {
				const u = g.users[i];
				if(u._id == payload.uid) {
					Vue.set(u, "online", false);
				}
			}
		},
		
		userStep(state, payload:{uid:string, step:string}) {
			let g = <IGAme>state.game;
			for (let i = 0; i < g.users.length; i++) {
				const u = g.users[i];
				if(u._id == payload.uid) {
					// console.log("Save step", payload.step);
					Vue.set(u, "currentStep", payload.step);
				}
			}
		},
		
		saveStep(state, payload:{step:string}) {
			state.user.currentStep = payload.step;
			for (let i = 0; i < state.game.users.length; i++) {
				if(state.game.users[i]._id == state.user._id) {
					state.game.users[i].currentStep = payload.step;
				}
			}
			SockController.instance.sendMessage(SOCK_ACTIONS.CURRENT_STEP, {uid:state.user._id, step:payload.step});
			Api.post("user/saveStep", {pid:state.user.privateID, step:payload.step});
		},
		
		addTimestampToSave(state, payload:{key:string, date:Date}) {
			if(!state.timestampsToSave) state.timestampsToSave = {};
			state.timestampsToSave[payload.key] = payload.date;
			Store.set("timestampsToSave", JSON.stringify(state.timestampsToSave));
		},
		
	},






	actions: {
		async startApp({ commit, state, dispatch }, payload) {
			//Security to make sure startApp isn't executed twice if changing URL while loading
			if (startPromise && payload.force !== true) return startPromise;
			
			state.initComplete = false;
				
			if(Store.get("session")) {
				state.session = Store.get("session");
				let res:any;
				try {
					res = await Api.get("admin/auth");
				}catch(error) {
					res = null;
				}
				if(res && res.token) {
					commit("authenticate", res.token);
				}else{
					commit("logout");
				}
			}

			if(payload.i18n) {
				state.i18n = payload.i18n;
				//Load labels and init i18n
				let opts:any = {};
				if(Utils.getRouteMetaValue(payload.route, "isAdmin") === true) opts.incadmin = true;
				else if(Utils.isTestDomain()) opts.incztest = true;
				else if(Utils.getRouteMetaValue(payload.route, "isHome") !== true) opts.incgame = true;
				let res = await Api.get("labels", opts);
				let dec =  AES.decrypt(res, "w6NwFzzRAzhGFXHZBHUktyrtmNaJxjJ6CJFVg3GYa22RrfcJRt46848eBkwWmWzExfWbZckWeuj52hH3Mw4kKAwkGB9cvPFhDR5rNwLJRBfceuarbMAU8wE6JwGKpkrJ").toString(enc.Utf8);
				dispatch("setLabels", JSON.parse(dec));

				//Load labels from local
				// let labels = AES.decrypt(Labels.data, "w6NwFzzRAzhGFXHZBHUktyrtmNaJxjJ6CJFVg3GYa22RrfcJRt46848eBkwWmWzExfWbZckWeuj52hH3Mw4kKAwkGB9cvPFhDR5rNwLJRBfceuarbMAU8wE6JwGKpkrJ").toString(CryptoJS.enc.Utf8);
				//remove comments
				// labels = labels.replace(/\s+\/\/.*/gi, "");
				//Replace SoundEffect refs by actual values
				// labels = labels.replace(/SoundEffect\.([^,\n]*)/gi, (a, b, c, d) => {
				// 	b = b.trim();
				// 	return SoundEffect[b];
				// })
				// let json;
				// try {
				// 	json = JSON.parse(labels)
				// }catch(error) {
				// 	console.error(error);
				// 	dispatch("alert", "Wrong label data structure");
				// 	return;
				// }
				// dispatch("setLabels", json);
			}
			
			// SockController.instance.connect();

			let u = Store.get("user");
			if(u && u !="null") {
				state.user = JSON.parse(u);
			}
			if(Store.get("game")) {
				state.game = JSON.parse(Store.get("game"));
				let res, fail:boolean;
				//Get fresh game object
				try {
					res = await Api.get("game/infos", {gid:state.game._id});
					fail = res.game == null;
					let resTref = await Api.get("game/tref");
					let ref = new Date( AES.decrypt(resTref.tref, "iNrOM99SrcUk0LKtBHq7b9J8A3yemOiz3wWPzYyAhmDUaBpRB62R5rxHlp2gzVGOZTVlsKdGW6c0KwM4vOREVvbzBKnxV0Nf0XSCJOb2wxa1BJcJ8a3yVU2KVLwkUENDpKAFsyWnmB8302ZekOWATrDeyQDo6hDzrNgEVhX2IzQySLwuwy5rFKhdy4ssQdmZmJ4ff9TC").toString(enc.Utf8) );
					state.toffset = ref.getTime() - new Date().getTime();
				}catch(error) {
					fail = true;
				}
				if(!fail) {
					state.game = res.game;
					let mateIds = [];
					mateIds.push(Utils.getTeamMate()._id);
					SockController.instance.mates = mateIds;
				}else{
					state.game = null;
					state.user = null;
				}
			}

			if(state.user) {
				let res = await Api.get("user", {uid:state.user._id});
				if(res.success) {
					state.user= res.user;
					Store.set("user", JSON.stringify(state.user));
					SockController.instance.user = state.user;
					BleDeviceHelper.instance.initFallbackFromUser(state.user);
				}
				let tsave = Store.get("timestampsToSave");
				if(tsave) {
					let json = JSON.parse(tsave);
					//Clean up fallback data, they will be set back by Utils.saveTimestamp() if they fail
					state.timestampsToSave = {};
					Store.remove("timestampsToSave");
					for (const key in json) {
						try {
							console.log("UPDATE TS ", key);
							await Utils.saveTimestamp(key, 0, new Date(json[key]));
						}catch(e) { }
					}
				}
			}
			
			startPromise = new Promise(async (resolve, reject) => {
				commit("initComplete", true);
				resolve();
			});

			return startPromise;
		},

		setLabels({state}, payload) {
			for (const lang in payload) {
				state.i18n.setLocaleMessage(lang, payload[lang]);
			}
		},

		alert({commit}, payload) { commit("alert", payload); },
		
		setMicAccessRefusedReason({commit}, payload) { commit("setMicAccessRefusedReason", payload); },

		openTooltip({commit}, payload) { commit("openTooltip", payload); },
		
		closeTooltip({commit}) { commit("closeTooltip", null); },

		confirm({commit}, payload) { commit("confirm", payload); },

		setLanguageCode({commit}, payload) { commit("setLanguageCode", payload); },
		
		authenticate({commit}, payload) { commit("authenticate", payload); },
		
		startGame({commit}, payload) { commit("startGame", payload); },
		
		userJoined({commit}, payload) { commit("userJoined", payload); },
		
		userLeft({commit}, payload) { commit("userLeft", payload); },
		
		userStep({commit}, payload) { commit("userStep", payload); },
		
		saveStep({commit}, payload) { commit("saveStep", payload); },
		
		updateUser({commit}, payload:IUser) { commit("updateUser", payload); },
		
		addTimestampToSave({commit}, payload:{key:string, date:Date}) { commit("addTimestampToSave", payload); },
	}
})
