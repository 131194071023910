















import Utils from '@/utils/Utils';
import IGame from 'src_back/db/models/game/IGame';
import IUser from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';
import ContactForm from './ContactForm.vue';
import ContactInfos from './ContactInfos.vue';

@Component({
	components:{
		Button,
		ContactForm,
		ContactInfos,
	}
})
export default class SkypeCall extends Vue {

	public ready:boolean = false;
	public showForm:boolean = false;
	public showInfos:boolean = false;
	public contactAvailable:boolean = false;
	public teammateName:string = "";
	
	private _audio:HTMLAudioElement;
	private _interval:number;
	private _timeout:number;

	public mounted():void {
		this.teammateName = Utils.getTeamMate().nickname;
		this._audio = new Audio(require("@/assets/sounds/skype.mp3"));
		this._audio.play();
		this._interval = setInterval(_=>{
			this._audio.play();
		}, 5000);
	}

	public beforeDestroy():void {
		this._audio.pause();
		clearInterval(this._interval);
	}

	public toggleContact():void {
		if(this.contactAvailable) {
			this.showInfos = !this.showInfos;
		}else{
			this.showForm = !this.showForm;
		}
	}

	@Watch("$store.state.game.users", {deep:true, immediate:true})
	private checkIfMateOnline(v?):void {
		let users:IUser[] = this.$store.state.game.users;
		let allOnline = true;
		for (let i = 0; i < users.length; i++) {
			if(!Utils.isUserOnCollabPage(users[i], this.$router)) allOnline = false;
		}
		this.ready = allOnline;
		if(this.ready) {
			clearTimeout(this._timeout);
			this._timeout = setTimeout(_=> {
				this.$router.push({name:"game:d", params:{step:"debrief", lang:this.$store.state.lang}});
			}, 3000)
		}
	}

}
