










import Button from '@/components/Button.vue';
import MailForm from '@/components/MailForm.vue';
import Store from '@/store/Store';
import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		Button,
		MailForm,
	}
})
export default class EndEnigmaForm extends Vue {

	public loadingMail:boolean = false;

	public get inviteCode():string { return Store.get("inviteCode"); }

	public mounted():void {
		gsap.to(this.$el, 1, {maxHeight:350, ease:"sine.inOut"});
		Utils.showTransition(<HTMLDivElement>this.$el, .5);
	}

	public beforeDestroy():void {
		
	}
}
