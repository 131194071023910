















import Utils from '@/utils/Utils';
import gsap, { random } from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class Persona extends Vue {

	@Prop({default:"scientist"})
	public speaker:string;
	
	public disposed:boolean = false;
	public flowBlood:boolean = false;
	public bloodParts:number = 0;

	public get bloodClasses():string[] {
		let res = ["blood"]
		if(this.flowBlood) res.push("flow");
		return res;
	}

	public mounted():void {
		this.onSpeakerChange();
	}

	public beforeDestroy():void {
		this.disposed = true;
	}

	@Watch("speaker")
	public async onSpeakerChange():Promise<void> {
		this.flowBlood = false;
		if(this.speaker == "scientist_dead") {
			this.burstParticles();
			setTimeout(_=> {
				this.flowBlood = true;
			}, 100)
		}
	}

	public async resizeBlood():Promise<void> {
		let s = <HTMLImageElement>this.$refs.scientistDead;
		let b = <HTMLImageElement>this.$refs.blood;
		let p = <HTMLImageElement>this.$refs.bloodParts;
		let boundsS = s.getBoundingClientRect();
		if(boundsS.width == 0 || boundsS.height == 0) return;
		b.style.width = (boundsS.width/689 * 147)+"px";
		b.style.height = (boundsS.height/817 * 360)+"px";
		b.style.left = (boundsS.width/689 * 158)+"px";
		b.style.top = (boundsS.height/817 * 406)+"px";
		p.style.left = (boundsS.width/689 * 266)+"px";
		p.style.top = (boundsS.height/817 * 375)+"px";
	}

	private async burstParticles():Promise<void> {
		//Cleanup before creating them to avoid caching issues
		//if going back to death animation
		this.bloodParts = 0;
		await this.$nextTick();
		this.resizeBlood();

		this.bloodParts = 15;
		await this.$nextTick();
		let speeds = [];
		let directions = [];
		let positions = [];
		let rotations = [];
		let rotationSpeeds = [];

		//Define positions and velocities
		for (let i = 0; i < this.bloodParts; i++) {
			let a = -Math.random() * Math.PI*1.5;
			let s = Math.random()*4+1;
			speeds.push({x:Math.cos(a)*s, y:Math.sin(a)*s});
			rotations.push(Math.random()*360);
			rotationSpeeds.push((Math.random()-Math.random())*40+10);
			
			let d = this.$refs.droplet[i];
			let left = Math.cos(a) * 40 * Math.random();
			let top = Math.sin(a) * 40 * Math.random();
			positions.push({x:left, y:top});
			gsap.set(d, {top, left, width:Math.random()*7+2, height:Math.random()*7+2});
		}

		let bounds = this.$el.getBoundingClientRect();
		let boundsRef = (<HTMLElement>this.$refs.bloodParts).getBoundingClientRect();
		let floor = bounds.top + bounds.height - boundsRef.top + 10;
		
		//Animate particles
		gsap.ticker.add(_=> {
			if(this.disposed) return;
			for (let i = 0; i < this.bloodParts; i++) {
				let d = this.$refs.droplet[i];
				positions[i].x += speeds[i].x;
				positions[i].y += speeds[i].y;
				speeds[i].y += Math.max(.05, (i*3)*.005);
				speeds[i].x *= .97;

				//Stop on the floor
				let top = Math.min(positions[i].y, floor);
				if(top == floor) {
					speeds[i].x = 0;
					rotationSpeeds[i] = 0;
				}
				let left = positions[i].x;
				let rotation = rotations[i];
				rotations[i] += rotationSpeeds[i];
				gsap.set(d, {left, top, rotation});
			}
		})
	}

}
