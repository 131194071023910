

































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Tooltip from "./components/Tooltip.vue";
import Confirm from "./views/Confirm.vue";
import Alert from "./views/AlertView.vue";
import BLEStatusView from './views/BLEStatusView.vue';
import MicAccessStatus from './views/MicAccessStatus.vue';
import Utils from './utils/Utils';
import CirclesRendererCanvas from './components/CirclesRendererCanvas.vue';
import gsap from 'gsap';
import FullscreenView from "./views/FullscreenView.vue";
import { log } from "three";

@Component({
	components:{
		Alert,
		Tooltip,
		Confirm,
		BLEStatusView,
		FullscreenView,
		MicAccessStatus,
		CirclesRendererCanvas,
	}
})
export default class App extends Vue {

	private preloadedImages:string[] = [];
	private preloadedSounds:string[] = [];
	private loadCount:number = 0;
	private percent:number = 0;
	private showPreload:boolean = false;
	private loadComplete:boolean = false;
	private showPreloadedItems:boolean = false;
	private loaderData:{a:number, s:number}[] = [];
	private loaderSize:number = 50;
	private loaderThick:number = 2;
	private loaderSpace:number = 6;
	private timeoutStuckCheck:number;
	private metaTitle:string = null;
	private metaDescription:string = null;

	public get classes():string[] {
		let res = ["app"];
		if(Utils.getRouteMetaValue(this.$route, "fullSize")) res.push("fullSize")
		if(Utils.getRouteMetaValue(this.$route, "scrollable")) res.push("scrollable")
		if(Utils.getRouteMetaValue(this.$route, "fullWidth")) res.push("fullWidth")
		return res;
	}

	public mounted():void {
		(<HTMLElement>this.$el).addEventListener("wheel", (e:MouseWheelEvent)=> {
			if(e.ctrlKey) e.preventDefault();
		})
		
		for (let i = 0; i < 3; i++) {
			this.loaderData.push({a:0, s:0});
		}
		this.loaderData[0].a = Math.PI*.5;
		gsap.to(this.loaderData[0], .5, {s:Math.PI*2, repeat:-1, ease:"linear"});
		this.loaderData[1].a = Math.PI*.6;
		gsap.to(this.loaderData[1], .8, {s:-Math.PI*2, repeat:-1, ease:"linear"});
		// this.loaderData[2].a = Math.PI*.2;
		// gsap.to(this.loaderData[2], 2.5, {s:-Math.PI*2, repeat:-1, ease:"linear"});
		this.onRouteChange();
	}

	public beforeDestroy():void {
		
	}

	@Watch("$route")
	public onRouteChange():void {
		
		let key = "meta."+this.$route.name;
		let metas:{title:string, description:string} = <any>this.$t(key);
		if(this.$te('meta.default.title')) {
			this.metaTitle = metas.title || this.$t('meta.default.title').toString();
			this.metaDescription = metas.description || this.$t('meta.default.description').toString();
		}

		if(this.loadComplete) return;

		if(this.$route.name == null) return;

		//Don't preload on homepage or if already preloaded
		if(this.$route.name != null && Utils.getRouteMetaValue(this.$route, "preload") !== true) {
			this.loadComplete = true;
			return;
		}

		this.showPreload = true;
		
		//Preload elements
		let preloaders = [require.context("@/assets/icons/"), require.context("@/assets/loader/"), require.context("@/assets/images/"), require.context("@/assets/numbers/")];
		if(Utils.isTestDomain()) {
			preloaders.push(require.context("@/assets/enigma/heptagon/"), require.context("@/assets/enigma/constellations/"))
		}
		for (let i = 0; i < preloaders.length; i++) {
			preloaders[i].keys().forEach(img => {
				this.preloadedImages.push(preloaders[i](img));
				let loader = new Image();
				loader.src = preloaders[i](img);
				loader.addEventListener("error", (e)=> {
					console.error("Loading image failed ! error");
					console.log(img);
					console.log(e);
				})
				loader.addEventListener("abort", (e)=> {
					console.error("Loading image failed ! abort");
					console.log(e);
				})
				loader.addEventListener("stalled", (e)=> {
					// console.error("Loading image failed ! stalled");
					// console.log(e);
				})
				loader.addEventListener("load",(e)=> {
					// console.log("IMAGE OK");
					this.onLoadComplete();
				})
			});
		}

		if(!Utils.isTestDomain()) {
			preloaders = [require.context("@/assets/sounds/", false), require.context("@/assets/sounds/syllables", false), require.context("@/assets/sounds/typewrite_human", false), require.context("@/assets/sounds/typewrite_alien", false)];
		}else{
			preloaders = [];
		}
		for (let i = 0; i < preloaders.length; i++) {
			preloaders[i].keys().forEach(audio => {
				this.preloadedSounds.push(preloaders[i](audio));
				let loader = new Audio();
				loader.src = preloaders[i](audio);
				loader.preload = "auto";
				loader.addEventListener("error", (e)=> {
					console.error("Loading image failed ! error");
					console.log(e);
				})
				loader.addEventListener("abort", (e)=> {
					console.error("Loading image failed ! abort");
					console.log(e);
				})
				loader.addEventListener("stalled", (e)=> {
					// console.error("Loading image failed ! stalled");
					// console.log(e);
				})
				loader.addEventListener("canplaythrough",(e)=> {
					this.onLoadComplete();
				})
				loader.load();
			});
		}
	}

	public onLoadComplete():void {
		this.loadCount++;
		let total = this.preloadedSounds.length + this.preloadedImages.length;
		this.percent = Math.round(this.loadCount/total*100);
		this.loaderData[2].a = Math.PI*2 * this.percent/100;
		this.loaderData[2].s = -this.loaderData[2].a/2 + Math.PI/2;

		//Avoid getting stuck if a loading never ends
		clearTimeout(this.timeoutStuckCheck);
		this.timeoutStuckCheck = setTimeout(_=> {
			console.log("Loading stuck at", this.loadCount +"/"+ total);
			this.loadCount = total-1;
			this.onLoadComplete();
		}, 2000)

		if(this.loadCount >= total) {
			clearTimeout(this.timeoutStuckCheck);
			this.percent = 100;
			gsap.to(this.loaderData[0], .5, {a:Math.PI*2});
			gsap.to(this.loaderData[1], .5, {a:Math.PI*2, onComplete:() => {
				gsap.delayedCall(.4, _=> {
					this.percent = -1;
				})
				gsap.to(this.$refs.loader, .3, {opacity:0, delay: 1});
				gsap.to(this.loaderData[0], .8, {a:0, s:Math.random()*Math.PI*2, delay:.5});
				gsap.to(this.loaderData[1], .8, {a:0, s:Math.random()*Math.PI*2, delay:.5});
				gsap.to(this.loaderData[2], .8, {a:0, s:Math.random()*Math.PI*2, delay:.5});
				gsap.to(this, .5, {loaderThick:0, ease:"sine.in", delay:.8});
				gsap.to(this, 1.3, {loaderSpace:15, ease:"back.in(6)", onComplete: async ()=> {
					this.showPreload = false;
					await this.$nextTick();
					this.loadComplete = true;
					// console.log("Load complete : ", this.loadCount);
				}});
			}});
		}
		
	}

}
