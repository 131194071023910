
























import Button from '@/components/Button.vue';
import BleDeviceHelper, { BleEvent } from '@/utils/BleDeviceHelper';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class BLEStatusView extends Vue {

	public errorMessage:string = "";
	public askConnectReason:string = "";
	public error:boolean = false;
	public deviceOK:boolean = false;
	public serverOK:boolean = false;
	public connected:boolean = false;
	public showReload:boolean = false;
	public showConnect:boolean = true;
	public connectingBluetooth:boolean = false;

	public get classes():string[] {
		let res = ["loadBar"];
		if(this.error) res.push('error');
		else if(this.connected) res.push('connected');
		else if(this.serverOK) res.push('serverOK')
		else if(this.deviceOK) res.push('deviceOK');
		return res;
	}

	public mounted():void {
		BleDeviceHelper.instance.addEventListener(BleEvent.OPEN_CONNECTION_MODAL, this.open);
		BleDeviceHelper.instance.addEventListener(BleEvent.START_CONNECTING, this.onStartConnect);
		BleDeviceHelper.instance.addEventListener(BleEvent.DEVICE_FOUND, this.onDeviceFound);
		BleDeviceHelper.instance.addEventListener(BleEvent.SERVER_FOUND, this.onServerFound);
		BleDeviceHelper.instance.addEventListener(BleEvent.DEVICE_CONNECTED, this.onServiceFound);
		BleDeviceHelper.instance.addEventListener(BleEvent.CONNECT_ERROR, this.onError);
		BleDeviceHelper.instance.addEventListener(BleEvent.CONNECT_CANCELED, this.onCancelConnect);
		BleDeviceHelper.instance.addEventListener(BleEvent.DEVICE_LOST, this.onDeviceLost);
		this.onRouteChange();
	}

	public beforeDestroy():void {
		
	}

	@Watch("$route")
	public onRouteChange():void {
		//Allows to ignore BLE check for local testing (No Ble Check)
		if(this.$route.query.nbc !== undefined) return;

		//If route doesn't needs BLE connection, ignore check
		if(Utils.getRouteMetaValue(this.$route, 'needBle') !== true) return;

		//If BLE is connected, ignore check
		if(BleDeviceHelper.instance.connected) {
			this.askConnectReason = null;
			return;
		}

		let currentStep = this.$route.params.step;
		if(currentStep) {
			//If accessing a dialog step other than introduction ones and BLE device
			//isn't connected, display layer
			let noBleSteps = ["instructions", "start", "connectDevice", "connectDeviceAfterIntro"]
			if(noBleSteps.indexOf(currentStep) == -1) {
				this.open();
			}
		}else{
			this.open();
		}
	}

	@Watch("askConnectReason")
	public onReasonChange():void {
		if(this.askConnectReason) {
			this.serverOK = false;
			this.deviceOK = false;
			this.connected = false;
			this.showConnect = true;
			this.showReload = false;
		}
	}

	public onStartConnect(event:BleEvent):void {
		this.show();
	}

	public onDeviceFound(event:BleEvent):void {
		this.deviceOK = true;
	}

	public onServerFound(event:BleEvent):void {
		this.serverOK = true;
	}

	public onServiceFound(event:BleEvent):void {
		this.connected = true;
		this.askConnectReason = null;
		setTimeout(_=> {
			this.hide();
		}, 2000);
	}

	public onError(event:BleEvent):void {
		console.log("BIIIIIIIIIIIIIIIITE");
		this.error = true;
	}

	public onCancelConnect(event:BleEvent):void {
		this.hide();
	}

	public onDeviceLost(event:BleEvent):void {
		this.serverOK = false;
		this.deviceOK = false;
		this.connected = false;
		//If route doesn't needs BLE connection, don't show status
		if(Utils.getRouteMetaValue(this.$route, 'needBle') !== true) return;
		this.askConnectReason = "lost";
	}

	public show():void {
		gsap.to(this.$refs.loadBar, {duration:.25, top:0});
	}

	public hide():void {
		gsap.to(this.$refs.loadBar, {duration:.25, top:-10});
	}

	public async onClickConnect(event:MouseEvent):Promise<void> {
		if(this.connectingBluetooth) return;

		this.connectingBluetooth = true;
		try {
			await BleDeviceHelper.instance.connect(true);
		}catch(error) {
			if(error.message.indexOf("cancel") > -1) {
				this.connectingBluetooth = false;
			}else{
				this.showReload = true;
				this.showConnect = false;
				this.connectingBluetooth = false;
				this.errorMessage = this.$t("errors.connectFailed").toString();
			}
			return;
		}
		this.showConnect = false;
		this.showReload = false;
		this.connectingBluetooth = false;
	}

	public onClickReload():void {
		document.location.reload();
	}

	private open(e?:BleEvent):void {
		if(this.$route.query.nbc !== undefined) return;
		
		this.askConnectReason = e && e.prop? e.prop : "connectGate";
	}

}
