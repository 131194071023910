


















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import gsap, { MorphSVGPlugin, TimelineMax, TweenLite, Linear } from 'gsap/all';
import SVGWave from '@/utils/SVGWave';

@Component({
	components:{
	}
})
export default class AlienSymbol extends Vue {

	@Prop()
	public value!:number;

	@Prop()
	public isSlot:boolean;

	@Prop()
	public enabled:boolean;

	public timelines:TimelineMax[];
	public computedValue:number = null;

	public get classes():any {
		let res = ["aliensymbol"];
		if(!this.enabled) res.push("disabled");
		return res;
	}
	
	public mounted():void {
		this.computedValue = this.value;
		this.$nextTick().then(_=> {
			this.start();
		})
	}

	public beforeDestroy():void {
		
	}

	public stop():void {
		gsap.killTweensOf(this.$el);
		for (let i = 0; i < this.timelines.length; i++) {
			const t = this.timelines[i];
			t.kill();
		}
	}

	private start():void {
		if(this.isSlot) {
			let paths = this.$el.getElementsByTagName("path");
			this.timelines = [];
			for (let i = 0; i < paths.length; i++) {
				this.timelines.push(SVGWave.execute(paths[i], {
					taperStart:0,
					loose:true,
					invertFlow:Math.random()>.5,
					length:(this.isSlot?  80 : 50),
					angle:Math.random()*360,
					magnitude:Math.random() + (this.isSlot?  2 : 1),
					phase:0,
					duration:Math.random() + (this.isSlot?  1 : 2),
					start:0,
					repeat:-1}))
			};
		}
		gsap.set(this.$el, {scale:1});
		gsap.from(this.$el, {duration:1, scale:0, ease:"elastic.out(1, .4)"});

		if(!this.isSlot) {
			gsap.to(this.$refs.circle, {duration:1+Math.random(), scaleX:1.025+Math.random()*.05, ease:"sine.inOut", yoyo:true, repeat:-1, delay:Math.random()})
			gsap.to(this.$refs.circle, {duration:1+Math.random(), scaleY:1.025+Math.random()*.05, ease:"sine.inOut", yoyo:true, repeat:-1, delay:Math.random()})
		}
	}

	@Watch("value")
	private onValueChange(newV, oldV):void {
		//Cleanup previous animations
		for (let i = 0; i < this.timelines.length; i++) {
			this.timelines[i].clear();
		}
		
		if(newV == null) {
			gsap.to(this.$el, {duration:.3, scale:0, ease:"back.in(2.8)", onComplete:_=> {
				this.computedValue = this.value;
			}});
		}else{
			this.computedValue = this.value;
			this.$nextTick().then(_=> {
				this.start();
			})
		}
	}
}
