






















import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import { IUserContact } from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';
import CirclesRendererCanvas from './CirclesRendererCanvas.vue';

@Component({
	components:{
		Button,
		CirclesRendererCanvas,
	}
})
export default class ContactForm extends Vue {

	@Prop()
	public redirectOnSubmit:boolean

	public dataLoaded:boolean = false;
	public updateMode:boolean = false;
	public loading:boolean = false;
	public error:boolean = false;
	public mail:string = "";
	public phone:string = "";
	public other:string = "";

	public get submitLabel():string {
		return this.updateMode? this.$t('global.update').toString() : this.$t('global.submit').toString();
	}

	public async mounted():Promise<void> {
		
		//Get teammate contact infos
		let gid = this.$store.state.game._id;
		let uid = this.$store.state.user._id;
		let data = { uid, gid };
		let res;
		try {
			res = await Api.get("user/contact", data);
		}catch(error) {
			res = null;
			console.log(error)
			//TODO
		}
		if(res && res.success) {
			let contact = res.contact;
			this.mail = contact? contact.mail : "";
			this.phone = contact? contact.phone : "";
			this.other = contact? contact.other : "";
			if(this.mail || this.phone || this.other) this.updateMode = true;
		}
		
		this.dataLoaded = true;
		await this.$nextTick();

		Utils.showTransition(<HTMLDivElement>this.$refs.form);
	}

	public beforeDestroy():void {
		
	}

	public async submitForm():Promise<any> {
		this.loading = true;
		this.error = null;
		await Utils.promisedTimeout(250);
		let data = {
			pid:this.$store.state.user.privateID,
			mail:this.mail,
			phone:this.phone,
			other:this.other,
		}

		let res;
		try {
			res = await Api.patch("user/contact", data);
		}catch(error) {
			this.error = true;
			this.loading = false;
			console.log(error);
		}
		
		if(!res || !res.success) {
			this.error = true;
		}
		await Utils.promisedTimeout(200);
		this.loading = false;
		if(this.redirectOnSubmit && !this.error) {
			this.$router.push({name:'game:d', params:{step:'contactDone', lang:this.$store.state.lang}})
		}
		if(!this.error) {
			await Utils.hideTransition(<HTMLDivElement>this.$refs.form);
			this.$emit("submit");
		}
	}

}
