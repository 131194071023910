

















































































import Box3D from '@/components/home/Box3D.vue';
import MailForm from '@/components/MailForm.vue';
import RentForm from '@/components/RentForm.vue';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from '../components/Button.vue';
import Api from '../utils/Api';

@Component({
	components: {
		Box3D,
		Button,
		RentForm,
		MailForm,
	}
})
export default class Home extends Vue {

	public ready:boolean = false;
	public introDone:boolean = false;
	public showBox:boolean = false;
	public hideContent:boolean = false;
	public mailSubmitted:boolean = false;
	public contentId:string = '';

	public get classes():string[] {
		let res = ["home"];
		if(this.ready) res.push("show");
		return res;
	}

	public get viewIcon():string {
		if(this.showBox) {
			return require("@/assets/icons/eye_on_black.svg");
		}else{
			return require("@/assets/icons/eye_off_black.svg");
		}
	}

	public mounted():void {
		this.onRouteChange();

		//Opening animation only on homepage
		// if(this.$route.name != "home") {
			this.introDone = true;
			this.open();
		// }
	}

	public beforeDestroy():void {
		
	}

	public open():void {
		if(this.ready) return;
		this.ready = true;
		gsap.set((<Vue>this.$refs.box).$el, {opacity:0});
		gsap.to((<Vue>this.$refs.box).$el, 1, {opacity:1, ease:"sine.in", onComplete:_=> {
			document.dispatchEvent(new Event('ssr-render-event'));
		}});
	}

	public toggleView():void {
		this.showBox = !this.showBox;
		if(!this.showBox) this.hideContent = false;
	}

	@Watch("$route")
	private onRouteChange():void {
		let cid = Utils.getRouteMetaValue(this.$route, "contentId");
		this.contentId = cid;
	}

}
