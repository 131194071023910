import store from '@/store';
import Vue from 'vue';
import Peer2peerHelper, { MediaConnection } from './Peer2peerHelper';
/**
* Created : 19/09/2020 
*/
export default class WalkieTalkie {

	public spectrumDataEmitter:number[] = [];
	public spectrumDataReceiver:number[] = [];

	private disposed:boolean;
	private _stream:MediaStream;
	private _audio:HTMLAudioElement;
	private _call:MediaConnection;
	private _audioContextEmitter:AudioContext;
	private _audioInputEmitter:MediaStreamAudioSourceNode;
	private _inputPointEmitter:GainNode;
	private _analyserNodeEmitter:AnalyserNode;
	private _audioContextReceiver:AudioContext;
	private _audioInputReceiver:MediaStreamAudioSourceNode;
	private _inputPointReceiver:GainNode;
	private _analyserNodeReceiver:AnalyserNode;
	private _FFT_SIZE = 2048;
	
	constructor(private onReady:Function, private onMateConnect?:Function) {
		this.initialize();
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public async start():Promise<void> {
		if(this._call) return Promise.resolve();

		this._call = Peer2peerHelper.instance.call(this._stream);
		this._call.on("stream", (stream:MediaStream)=> {
			//Doesn't work
			// var audioContext = new AudioContext();
			// var audioStream = audioContext.createMediaStreamSource(data);
			// console.log(audioContext.destination);
			// audioStream.connect(audioContext.destination);
			// console.log("ON stream received");
			this.play(stream);
		});
	}
	
	public stop():void {
		this._call.close();

		//This is necessary to "free" the mic
		for (let i = 0; i < this._stream.getTracks().length; i++) {
			this._stream.getTracks()[i].stop();
		}
	}

	public static requestMicAccess():Promise<MediaStream> {
		let constraints:any = {
			"audio": {
				"mandatory": {
					echoCancellation:true,
				},
				"optional": [
					{"googEchoCancellation": "true"},
					{"googAutoGainControl": "false"},
					{"googNoiseSuppression": "false"},
					{"googHighpassFilter": "false"}
				]
			},
		}
		let prom = navigator.mediaDevices.getUserMedia(constraints);
		prom.catch((error:Error)=> {
			store.dispatch("setMicAccessRefusedReason", error.name)
		})
		return prom;
	}

	public mute():void {
		this._audio.volume = 0;
	}

	public unmute():void {
		this._audio.volume = 1;
	}

	/**
	 * Disposes everything and release the microphone
	 */
	public dispose():void {
		if(!this._audio) return;
		this._inputPointEmitter.disconnect();
		this._audioInputEmitter.disconnect();
		this._inputPointReceiver.disconnect();
		this._audioInputReceiver.disconnect();
		this._audioContextEmitter.close();
		this._audioContextReceiver.close();

		if(this._stream) {
			//This is necessary to "free" the mic
			for (let i = 0; i < this._stream.getTracks().length; i++) {
				this._stream.getTracks()[i].stop();
			}
		}

		this._stream = null;
		this._inputPointEmitter = null;
		this._audioInputEmitter = null;
		this._inputPointReceiver = null;
		this._audioInputReceiver = null;
		this._audioContextEmitter = null;
		this._audioContextReceiver = null;
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private async initialize():Promise<void> {
		Vue.observable(this);

		this._audio = new Audio();
		this._audio.autoplay = true;
		this._stream = await WalkieTalkie.requestMicAccess();
		Peer2peerHelper.instance.on("call", (caller:MediaConnection)=> {
			// console.log("User calls !");
			caller.answer(this._stream);
			caller.on('stream', (stream) => {
				// console.log("ON stream received after answer");
				this.play(stream)
			});
		});
		this.onReady();
		// this.start();
		
		//@ts-ignore
		let ac = window.AudioContext || window.webkitAudioContext;//Fuck you apple for still prefixing shits...
		this._audioContextEmitter = new ac();
		this._audioInputEmitter = this._audioContextEmitter.createMediaStreamSource(this._stream);
		this._inputPointEmitter = this._audioContextEmitter.createGain();
		this._inputPointEmitter.gain.value = 3;
		this._analyserNodeEmitter = this._audioContextEmitter.createAnalyser();
		this._analyserNodeEmitter.fftSize = this._FFT_SIZE;
		this._inputPointEmitter.connect( this._analyserNodeEmitter );
		this._audioInputEmitter.connect( this._inputPointEmitter );
		this.onStreamData();
	}

	private play(stream:MediaStream):void {
		// console.log("PLAY remote stream");
		this._audio.volume = 0;
		this._audio.srcObject = stream;
		this.startSpectrumAnalysis(stream);
		if(this.onMateConnect) this.onMateConnect();
	}

	private startSpectrumAnalysis(stream:MediaStream):void {
		//@ts-ignore
		let ac = window.AudioContext || window.webkitAudioContext;//Fuck you apple for still prefixing shits...
		this._audioContextReceiver = new ac();
		this._audioInputReceiver = this._audioContextReceiver.createMediaStreamSource(stream);
		this._inputPointReceiver = this._audioContextReceiver.createGain();
		this._analyserNodeReceiver = this._audioContextReceiver.createAnalyser();
		this._analyserNodeReceiver.fftSize = this._FFT_SIZE;
		this._inputPointReceiver.connect( this._analyserNodeReceiver );
		this._audioInputReceiver.connect( this._inputPointReceiver );
		// console.log("Start analysis !");
	}

	private onStreamData():void {
		if(this.disposed) return;
		requestAnimationFrame(_=> this.onStreamData());

        let freqByteData = new Uint8Array(this._analyserNodeEmitter.frequencyBinCount);
		this._analyserNodeEmitter.getByteTimeDomainData(freqByteData);
		let data1 = [];
		for (let i = 0; i < freqByteData.length; i++) {
			data1.push(freqByteData[i])
		}
		// console.log(max)
		this.spectrumDataEmitter = data1;
		

		if(this._analyserNodeReceiver) {
			freqByteData = new Uint8Array(this._analyserNodeReceiver.frequencyBinCount);
			this._analyserNodeReceiver.getByteTimeDomainData(freqByteData);
			let data2 = [];
			let max = 0;
			for (let i = 0; i < freqByteData.length; i++) {
				max = Math.max(max, freqByteData[i]);
				data2.push(freqByteData[i])
			}
			this.spectrumDataReceiver = data2;
		}
	}
}