import { render, staticRenderFns } from "./BLEStatusView.vue?vue&type=template&id=3da05474&scoped=true&"
import script from "./BLEStatusView.vue?vue&type=script&lang=ts&"
export * from "./BLEStatusView.vue?vue&type=script&lang=ts&"
import style0 from "./BLEStatusView.vue?vue&type=style&index=0&id=3da05474&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da05474",
  null
  
)

export default component.exports