import gsap from "gsap";
import { CSSPlugin, Draggable, InertiaPlugin } from 'gsap/all';
import Vue from 'vue';
import vueHeadful from 'vue-headful';
import VueI18n from 'vue-i18n';
import { Route } from 'vue-router';
import App from './App.vue';
import SockController from './controller/SockController';
import Labels from './i18n/Labels';
import './less/index.less';
import router from './router';
import store from './store';
import Store from './store/Store';
import Config from './utils/Config';
import Utils from './utils/Utils';


gsap.registerPlugin(Draggable,InertiaPlugin,CSSPlugin);
Vue.config.productionTip = false;
Config.init();

Vue.component('vue-headful', vueHeadful);

//Init i18n stuff
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: 'en',
	silentTranslationWarn:true,
	postTranslation:Labels.postTranslation
});


//Check if BLE API is available
let bleAvailable = false;
let isOpera = (navigator.userAgent.match(/Opera|OPR\//) ? true : false);
try {
	//@ts-ignore
	bleAvailable = navigator.bluetooth != undefined;
}catch(e) { /*ignore*/ }
//Opera supports BLE API but always refuses to create a connection with BLE devices
if(isOpera && !Utils.isMobile()) bleAvailable = false;

//Cleanup local storage if not at the proper version number
if(Store.get("v") != Config.STORAGE_VERSION.toString()) {
	Store.clear();
	Store.set("v", Config.STORAGE_VERSION.toString());
}

let isTestDomain = Utils.isTestDomain();
let lang = null;

router.beforeEach(async (to:Route, from:Route, next:Function) => {
	if (!store.state.initComplete) {
		//If first route, wait for data to be loaded
		await store.dispatch("startApp", { route: to, i18n:i18n })
	}
	if(Utils.getRouteMetaValue(to, "sockEnabled") === true){
		SockController.instance.connect();
	}else{
		SockController.instance.disconnect();
	}
	
	//Define locale from URL
	if(to.params.lang && !lang || to.params.lang != lang) {
		lang = to.params.lang;
		store.dispatch("setLanguageCode", lang);
	}

	//Redirect user if her/his browser isn't supported
	if(!bleAvailable
	&& to.query.nbc === undefined
	&& from.query.nbc === undefined
	&& Utils.getRouteMetaValue(to, "isAdmin") !== true
	&& Utils.getRouteMetaValue(to, "noBrowserCheck") !== true
	&& Utils.getRouteMetaValue(to, "needBle") === true) {
		router.push({name:"noble", params:{lang}, query:{url:to.fullPath}});

	//Check if user tries to access an admin page without
	//a proper session token
	}else if(!store.state.loggedin && Utils.getRouteMetaValue(to, "needAuth") === true) {
		router.push({name:"auth", params:{from:document.location.href, lang}});

	//Check if user tries to access an in-game page without
	//having proper data to localstorage
	}else if(Utils.getRouteMetaValue(to, 'needGameData') === true
		&& (!store.state.user || !store.state.game)
		&& to.name != "game:start"){ 
		router.push({name:"scanQRCode", params:{lang}});

	//All good, proceed please :)
	}else{
		if(store.state.user && Utils.getRouteMetaValue(to, 'needGameData') === true) {
			//Save current path to user's data
			store.dispatch("saveStep", {step:to.path});
		}
		//If "nbc" param was here, push it to next route
		//(this params disables ble connect check)
		if(from.query.nbc !== undefined && to.query.nbc === undefined) {
			to.query.nbc = "";
			router.push(to);
			return;
		}

		//Redirect to secondary enigma if user lands on the zte.st homepage
		if(isTestDomain && !Utils.getRouteMetaValue(to, "isZtestRoute")) {
			router.push({name:"enigma", params:{lang:lang}});
			return;
		}
		nextStep(next, to);
	}
});

function nextStep(next:Function, to:Route):void {
	next();
	let doAdvertise = Utils.getRouteMetaValue(router.currentRoute, "advertisePresence");
	if(doAdvertise) {
		SockController.instance.startAdvertisement();
	}else{
		SockController.instance.stopAdvertisement();
	}
}

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')