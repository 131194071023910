import BleDeviceHelper, { BleEvent } from '@/utils/BleDeviceHelper';
import {Vue} from "vue-property-decorator";
export default class AbstractBLEView extends Vue {

	private connectHandler:Function;

	public async mounted():Promise<void> {
		await BleDeviceHelper.instance.waitForDevice();
		this.onDeviceConnect();

		this.connectHandler = (e:BleEvent) => this.onDeviceConnect(e);
		BleDeviceHelper.instance.addEventListener(BleEvent.DEVICE_CONNECTED, this.connectHandler);
		
	}
	
	public beforeDestroy():void {
		BleDeviceHelper.instance.removeEventListener(BleEvent.DEVICE_CONNECTED, this.connectHandler);
	}

	protected onDeviceConnect(e:BleEvent = null):void {
		
	}

}