import gsap from 'gsap';
import { EventDispatcher } from './EventDispatcher';
import LetterEvent from './LetterEvent';
import { RoughEase } from 'gsap/all';

/**
* Created : 07/12/2020 
*/
export default class AlienLetterWritter extends EventDispatcher {

	private letter:string;
	private loopedLetter:string;
	private offset:number;
	private finalNode:HTMLSpanElement;
	private tmpNode:HTMLSpanElement;
	private alienNode:HTMLSpanElement;
	
	constructor(public index:number, public holder:HTMLDivElement, public isAlien:boolean, public delay:number, public effect:string, public timeScale) {
		super();
		this.letter = this.holder.innerText;

		if(this.isAlien) {
			this.renderAlien();
		}else{
			this.renderHuman();
		}

		if(this.effect) {
			switch(this.effect) {
				case "glitch":
					this.offset = Math.round(-20 - this.delay);
					let duration = (Math.random() * 2 + 2) * this.timeScale;
					gsap.to(this, {duration, offset:0,
						ease:"steps("+this.offset*2+")",
						delay:this.delay,
						onUpdate:()=>{
							if(Math.random() > .94) {
								this.loopLetters();
							}
						},
						onComplete:()=> {
							this.holder.innerText = this.letter;
						}}
					);
					break;
			}
		}

		this.initialize();
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public killTweens():void {
		gsap.killTweensOf(this.holder);
		gsap.set(this.holder, {opacity:1, scale:1, rotate:0});
		if(this.isAlien) {
			gsap.killTweensOf(this);
			gsap.killTweensOf(this.finalNode);
			gsap.killTweensOf(this.tmpNode);
			gsap.killTweensOf(this.alienNode);
		}
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private initialize():void {
		
	}

	private renderHuman():void {
		gsap.set(this.holder, {opacity:0, scale:0, rotate:(Math.random()-Math.random())*120});
		gsap.to(this.holder, {duration:.25, opacity:1, scale:1, rotate:0, delay:this.delay, ease:"back.out(2.5)",
		onStart:()=> {
			this.dispatchEvent(new LetterEvent("start", this.index, this.effect));
		}, onComplete:()=> {
			this.dispatchEvent(new LetterEvent("complete", this.index, this.effect));
		}});
		return;
	}

	private renderAlien():void {
		let duration = 2;
		this.finalNode = document.createElement("span");
		this.finalNode.className = "letter";
		this.tmpNode = document.createElement("span");
		this.tmpNode.className = "temp";
		this.alienNode = document.createElement("span");
		this.alienNode.className = "alien";
		this.finalNode.innerText = this.letter;
		this.tmpNode.innerText = this.letter;
		this.alienNode.innerText = this.letter.toUpperCase();
		this.holder.innerHTML = "";
		this.holder.appendChild(this.finalNode);
		this.holder.appendChild(this.tmpNode);
		this.holder.appendChild(this.alienNode);

		let conf = {template: "none.in", points:10, strength:2, clamp:false, randomize:true};
		gsap.from(this.holder, {duration:.25, opacity:0, delay:this.delay})

		gsap.from(this.tmpNode, {
			duration:duration*this.timeScale,
			opacity:0,
			rotate:(Math.random()-Math.random())*200,//Cannot work if the holder isn't position=absolute, which fucks up rendering
			delay:this.delay,
			ease:RoughEase.ease.config(conf),
			onStart:()=> {
				this.dispatchEvent(new LetterEvent("start", this.index, this.effect));
			}
		});
		
		gsap.set(this.alienNode, {scale:1+(Math.random()-Math.random())*1});
		gsap.to(this.alienNode, {
			duration:duration*this.timeScale,
			rotate:(Math.random()-Math.random())*200,
			opacity:0,
			scale:1,
			delay:this.delay,
			ease:RoughEase.ease.config(conf),
			onComplete:()=> {
				this.dispatchEvent(new LetterEvent("complete", this.index, this.effect));
				this.holder.innerHTML = this.letter;//Cleanup holder
			}
		});

		let code = this.letter.charCodeAt(0);
		if((code >= 65 && code <= 90) || (code >= 97 && code <= 122)) {
			//If it's a latin letter, cycle through other letters
			this.offset = Math.random() * 20 * this.timeScale;
			let d = Math.random() + 1;
			gsap.to(this, {duration:d*this.timeScale, offset:0, ease:"steps("+this.offset*2+")",
			delay:this.delay,
			onUpdate:()=>{
				if(Math.random() > .95) {
					this.loopLetters();
				}
			}});
		}
	}

	public loopLetters():void {
		let origin = this.letter.toUpperCase() == this.letter? 65 : 97;
		let letterCode = (this.letter.charCodeAt(0) - origin + this.offset) % (this.isAlien? 25 : 45) + origin;
		let newLetter = String.fromCharCode(Math.ceil(letterCode));
		if(this.loopedLetter != newLetter) {
			this.dispatchEvent(new LetterEvent("change", this.index, this.effect));
			this.loopedLetter = newLetter;
			if(this.isAlien) {
				this.tmpNode.innerText = newLetter;
				this.alienNode.innerText = newLetter.toUpperCase();
			}else{
				this.holder.innerText = newLetter;
			}
		}
	}
}