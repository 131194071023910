




















































































import Button from '@/components/Button.vue';
import Api from '@/utils/Api';
import BleDeviceHelper from '@/utils/BleDeviceHelper';
import SHA512 from '@/utils/SHA512';
import IUser from 'src_back/db/models/user/IUser';
import CryptoJS, {AES} from "crypto-js";
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import CirclesRendererCanvas from '@/components/CirclesRendererCanvas.vue';

@Component({
	components:{
		Button,
		CirclesRendererCanvas,
	}
})
export default class HealthCheckBox extends Vue {
	
	@Prop({default:'intro'})
	public step:string;
	
	@Prop({default:null})
	public code:string;

	public loading:boolean = true;
	public saving:boolean = false;
	public showReload:boolean = false;
	public doorOpened:boolean = false;
	public connectingDevice:boolean = false;
	public soundOK:boolean = false;
	public doorOK:boolean = false;
	public errorBLE:string = null;
	public tel:string = null;
	public mel:string = null;
	public timeout:number = null;
	public user:IUser = null;
	public stepsList:string[] = ["intro", "magnetTop", "magnetRight", "magnetBack", "motor", "irSensors", "close", "end"];
	public stepToCode:any = { magnetTop:"emulateReedTop", magnetRight:"emulateReedRight", magnetBack:"emulateReedBack" };

	public get bleConnected():boolean { return BleDeviceHelper.instance.connected; }
	public get topState():boolean { return BleDeviceHelper.instance.topValue; }
	public get backState():boolean { return BleDeviceHelper.instance.backValue; }
	public get rightState():boolean { return BleDeviceHelper.instance.rightValue; }
	public get irFront():number { return BleDeviceHelper.instance.irFrontValue; }
	public get irBack():number { return BleDeviceHelper.instance.irBackValue; }

	public get irFrontOk():boolean { return this.irFront < 50; };
	public get irBackOk():boolean { return this.irBack < 50; };
	public get irAllOk():boolean { return this.irBackOk && this.irFrontOk; };
	public get motorSoundOk():boolean { return this.doorOK && this.soundOK; };
	public get endText():string { return this.$t('health.end').toString().replace(/\{PLAYER\}/gi, this.user.nickname); };

	public async mounted():Promise<void> {
		// console.log(AES.encrypt("+33682302709", "myAmaz1ngKEY@OMG:o").toString())
		// console.log(AES.encrypt("francois.dursus@gmail.com", "myAmaz1ngKEY@OMG:o").toString())
		//Simply avoid having my mail and phone hardcoded and indexable
		this.tel = AES.decrypt("U2FsdGVkX197XxI/urtLW7pZaoT9zdkTLAkBcg1UeD4=", "myAmaz1ngKEY@OMG:o").toString(CryptoJS.enc.Utf8);
		this.mel = AES.decrypt("U2FsdGVkX1+8FyKGop0kF0ZsvzhvVhnz4uj+sv/RGKaE2tln7HsBCusG4gPIIFMS", "myAmaz1ngKEY@OMG:o").toString(CryptoJS.enc.Utf8);
		
		let res;
		try {
			res = await Api.get("user/codecheck", {code:this.code});
			if(!res.success) throw new Error();
			if(SHA512.encode(res.chk) != "62afc39a7181c020c22f1e6064e6889a0243d47ae376d885494b0d8ef061af3000f2b7a53e848b7ff1d5361af0330f40853303b2ac41447bfc6667e84c79dcfd") throw new Error();
		}catch(error) {
			this.loading = false;
			if(!error.alertDone) this.$store.dispatch("alert", this.$t('errors.codeCheckFailed'));
			this.$router.push({name:"home"});
			return;
		}
		
		this.loading = false;
		this.user = res.user;

		if(this.step != "intro" && this.step != "end" && !this.bleConnected) {
			BleDeviceHelper.instance.openConnectionModal("connectGateHealth");
		}
		if(this.step=="end") this.playComplete();
	}

	public beforeDestroy():void {
		
	}

	public async connectDevice():Promise<void> {
		this.connectingDevice = true;
		try {
			await BleDeviceHelper.instance.connect(true);
		}catch(error) {
			if(error.message.indexOf("cancel") > -1) {
				this.connectingDevice = false;
			}else{
				this.showReload = true;
				this.errorBLE = this.$t("errors.connectFailed").toString();
				this.connectingDevice = false;
			}
			return;
		}

		this.connectingDevice = false;
		this.nextStep(true);
	}

	public onClickReload():void {
		document.location.reload();
	}

	public onDoesntWork():void {
		this.nextStep(true);
	}

	public async nextStep(fail:boolean = false):Promise<void> {
		let nextStep = "";
		let emulateKey = this.stepToCode[this.step];
		let emulateKeys = [];

		this.saving = true;
		let res:any, success:boolean = true;
		if(this.step == "motor") {
			emulateKeys.push({key:"emulateSound", fail:!this.soundOK});
			emulateKeys.push({key:"emulateMotor", fail:!this.doorOK});
		}
		if(this.step == "irSensors") {
			emulateKeys.push({key:"emulateIrFront", fail:!this.irFrontOk});
			emulateKeys.push({key:"emulateIrBack", fail:!this.irBackOk});
		}
		if(emulateKey){
			emulateKeys.push({key:emulateKey, fail:fail});
		}
		let alertDone;
		if(Object.keys(emulateKeys).length > 0) {
			try {
				res = await Api.post("user/setEmulateState", {pid:this.user.privateID, keys:emulateKeys, fail:fail});
				success = res.success;
			}catch(error) {
				success = false;
				alertDone = error.alertDone;
			}
		}
		this.saving = false;

		if(success) {
			for (let i = 0; i < this.stepsList.length; i++) {
				if(this.stepsList[i] == this.step) {
					nextStep = this.stepsList[i+1];
					break;
				}
			}
			if(!fail) {
				if(nextStep == "end") {
					this.soundOK = this.doorOK = false;
					this.playComplete();
				}else{
					this.playSound();
				}
			}
			this.$router.push({name:"healthcheck", params:{step:nextStep, lang:this.$store.state.lang}});
		}else{
			if(!alertDone) this.$store.dispatch("alert", this.$t('errors.genericRequestError'));
		}
	}

	@Watch("topState")
	public topStateChanged():void { if(this.step == "magnetTop" && this.topState) this.nextStep(); }

	@Watch("rightState")
	public rightStateChanged():void { if(this.step == "magnetRight" && this.rightState) this.nextStep(); }

	@Watch("backState")
	public backStateChanged():void { if(this.step == "magnetBack" && this.backState) this.nextStep(); }

	@Watch("irFrontOk")
	public irFrontChanged():void { if(this.irFrontOk && this.step == "irSensors") this.playSound(); }

	@Watch("irBackOk")
	public irBackChanged():void { if(this.irBackOk && this.step == "irSensors") this.playSound(); }

	@Watch("irAllOk")
	public irAllOkChanged():void {
		if(this.irAllOk && this.step == "irSensors") {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(_=> {
					this.nextStep();
			}, 1000)
		}
	}

	@Watch("motorSoundOk")
	public motorSoundOkChanged():void {
		if(this.motorSoundOk && this.step == "motor") {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(_=> {
					this.nextStep();
			}, 250)
		}
	}

	public openBox():void {
		this.doorOpened = true;
		BleDeviceHelper.instance.openBox();
	}

	public closeBox():void {
		this.doorOpened = false;
		BleDeviceHelper.instance.closeBox();
		clearTimeout(this.timeout);
		this.timeout = setTimeout(_=> {
			this.nextStep();
		}, 1000)
	}

	private playSound():void {
		let sound = new Audio(require('@/assets/sounds/notification.mp3'));
		sound.play();
	}

	private playComplete():void {
		let sound = new Audio(require('@/assets/sounds/complete.mp3'));
		sound.play();
	}
	private encrypt(text) {
		// let cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
		// let encrypted = cipher.update(text);
		// encrypted = Buffer.concat([encrypted, cipher.final()]);
		// return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
	}

	private decrypt(text) {
		// let iv = Buffer.from(text.iv, 'hex');
		// let encryptedText = Buffer.from(text.encryptedData, 'hex');
		// let decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
		// let decrypted = decipher.update(encryptedText);
		// decrypted = Buffer.concat([decrypted, decipher.final()]);
		// return decrypted.toString();
	}
}
