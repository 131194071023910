import store from '@/store'
import Api from '@/utils/Api'
import Utils from '@/utils/Utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
import NameEnigma from '../views//game/NameEnigma.vue'
import AdminAuth from '../views/admin/AdminAuth.vue'
import Dialog from '../views/game/Dialog.vue'
import Frequency from '../views/game/Frequency.vue'
import Game from '../views/game/Game.vue'
import GameCollaboration from '../views/game/GameCollaboration.vue'
import GameEnd from '../views/game/GameEnd.vue'
import GameHints from '../views/game/GameHints.vue'
import GameStart from '../views/GameStart.vue'
import HealthCheckBox from '../views/HealthCheckBox.vue'
import Home from '../views/Home.vue'
import NoBLE from '../views/NoBLE.vue'
import ScanQrCode from '../views/ScanQrCode.vue'

Vue.use(VueRouter);

const Admin = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/Admin.vue'));
const AdminUsers = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminUsers.vue'));
const AdminGames = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminGames.vue'));
const AdminPrint = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminPrint.vue'));
const AdminBLEDevice = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminBLEDevice.vue'));
const AdminLeaderBoard = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminLeaderBoard.vue'));
const AdminContact = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminContact.vue'));
const AdminRent = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminRent.vue'));
const AdminMailing = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminMailing.vue'));
const AdminDiscordInvites = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminDiscordInvites.vue'));
const AdminLabels = () => lazyLoadView(import(/* webpackChunkName: "admin" */ '@/views/admin/AdminLabels.vue'));

const Enigma = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/Enigma.vue'));
const EnigmaLocation = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaLocation.vue'));
const EnigmaConstellations = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaConstellations.vue'));
const EnigmaHeptagon = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaHeptagon.vue'));
const EnigmaOrigin = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaOrigin.vue'));
const EnigmaHint = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaHint.vue'));
const EnigmaPendulum = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaPendulum.vue'));
const EnigmaWorms = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaWorms.vue'));
const EnigmaComposition = () => lazyLoadView(import(/* webpackChunkName: "enigma" */ '@/views/enigma/EnigmaComposition.vue'));

const WalkieTalkieTest = () => lazyLoadView(import(/* webpackChunkName: "debug" */ '@/views/WalkieTalkieTest.vue'));

const routes = [
	{
		path: '/:lang/',
		name: 'home',
		meta:{
			isHome:true,
			fullSize:true,
			noBrowserCheck:true,
			contentId:"",
		},
		component: Home,
	},
	{
		path: '/:lang/rent',
		name: 'rent',
		meta:{
			isHome:true,
			fullSize:true,
			noBrowserCheck:true,
			contentId:"rent",
		},
		component: Home,
	},
	{
		path: '/:lang/notify-me',
		name: 'notify-me',
		meta:{
			isHome:true,
			fullSize:true,
			noBrowserCheck:true,
			contentId:"mail",
		},
		component: Home,
	},
	{
		path: '/:lang/infos',
		name: 'infos',
		meta:{
			isHome:true,
			fullSize:true,
			noBrowserCheck:true,
			contentId:"info",
		},
		component: Home,
	},
	{
		path: '/:lang/buy',
		name: 'buy',
		meta:{
			isHome:true,
			fullSize:true,
			noBrowserCheck:true,
			contentId:"want",
		},
		component: Home,
	},
	{
		path: '/:lang/noble',
		name: 'noble',
		meta:{
			scrollable:true,
			noBrowserCheck:true,
			needGameData:false,
		},
		component: NoBLE,
	},
	{
		path: '/:lang/healthcheck/:code/:step?',
		name: 'healthcheck',
		props:true,
		meta:{
			needBle:false,
			needGameData:false,
			sockEnabled:false,
			scrollable:true,
		},
		component: HealthCheckBox,
	},
	{
		path: '/:lang/auth',
		name: 'auth',
		meta:{
			needGameData:false,
			noBrowserCheck:true,
		},
		component: AdminAuth,
	},
	{
		path: '/:lang/scanqrcode',
		name: 'scanQRCode',
		component: ScanQrCode,
	},
	{
		path: "/:lang/enigma",
		name: "enigma",
		meta:{
			isZtestRoute:true,
			preload:true,
			needBle:false,
			needGameData:false,
			sockEnabled:false,
			labelRoot:"ztest.dialogs."
		},
		redirect:to => {
			return {name:"enigma:d", params:{step:"intro", lang:to.params.lang}}
		},
		component: Enigma,
		beforeEnter: async (to, from, next) =>{
			//Allow only "zte.st" "locahost" and 192.168.0.10 domains for this route
			if(!Utils.isTestDomain()) {
				//bad origin, redirect to home
				router.push({name:"home", params:{lang:to.lang}});
			}else{
				next();
			}
		},
		children: [
			{
				path: "d/:step",
				name: "enigma:d",
				props:true,
				components: {
					enigma:Dialog
				},
			},
			{
				path: "location",
				name: "enigma:location",
				props:true,
				components: {
					enigma:EnigmaLocation
				},
			},
			{
				path: "hint/:id/:next?",
				name: "enigma:hint",
				props:true,
				components: {
					enigma:EnigmaHint
				},
			},
			{
				path: "constellations",
				name: "enigma:constellations",
				props:true,
				meta:{
					scrollable:true,
				},
				components: {
					enigma:EnigmaConstellations
				},
			},
			{
				path: "lacimehc",
				name: "enigma:composition",
				props:true,
				components: {
					enigma:EnigmaComposition
				},
			},
			{
				path: "pendulum",
				name: "enigma:pendulum",
				props:true,
				meta:{
					scrollable:true,
				},
				components: {
					enigma:EnigmaPendulum
				},
			},
			{
				path: "worms",
				name: "enigma:worms",
				props:true,
				components: {
					enigma:EnigmaWorms
				},
			},
			{
				path: "pentagon",
				name: "enigma:heptagon",
				props:true,
				meta:{
					scrollable:true,
				},
				components: {
					enigma:EnigmaHeptagon
				},
			},
			{
				path: "origin",
				name: "enigma:origin",
				props:true,
				meta:{
					scrollable:true,
				},
				components: {
					enigma:EnigmaOrigin
				},
			},
		]
	},
	{
		path: '/:lang/play',
		name: 'play',
		meta:{
			needBle:true,
			preload:true,
			needGameData:true,
			sockEnabled:true,
			fullscreenEnabled:true,
			advertisePresence:true,
		},
		component: Game,
		children: [
			{
				path: "s/:pubkey",
				name: "game:start",
				meta:{
					needBle:true,
					needGameData:false,
				},
				components: {
					game:GameStart
				},
			},
			{
				path: "d/:step",
				name: "game:d",
				components: {
					game:Dialog
				},
			},
			{
				path: "frequency",
				name: "game:frequency",
				components: {
					game:Frequency
				},
			},
			{
				path: "name",
				name: "game:name",
				components: {
					game:NameEnigma
				},
			},
			{
				path: "boxhints",
				name: "game:boxhints",
				meta:{
					scrollable:true,
				},
				components: {
					game:GameHints
				},
			},
			{
				path: "contact",
				name: "game:contact",
				meta:{
					needBle:true,
				},
				beforeEnter: async (to, from, next) =>{
					//Get teammate contact infos and redirect accordingly
					let game = store.state.game;
					let mate = Utils.getTeamMate();
					let data = {
						uid:mate._id,
						gid:game._id,
					}
					let res =await Api.get("user/contact", data);
					if(res.contact.mail || res.contact.phone || res.contact.other) {
						//Contact info found, show infos to user
						router.push({name:"game:d", params:{step:"showContactInfos"}});
					}else{
						//No contact info yet, ask for infos
						router.push({name:"game:d", params:{step:"askContactInfos"}});
					}
				},
			},
			{
				path: "collab",
				name: "game:collab",
				meta:{
					isCollab:true,
				},
				components: {
					game:GameCollaboration
				},
			},
			{
				path: "collab/part2",
				name: "game:collabpart2",
				meta:{
					isCollab:true,
				},
				components: {
					game:GameCollaboration
				},
			},
			{
				path: "end",
				name: "game:end",
				meta:{
					scrollable:true,
				},
				components: {
					game:GameEnd
				},
			},
		],
	},
	{
		path: "*",
		redirect:to => {
			return {name:"home", params:{lang:to.params.lang}}
		},
	},
	{
		path: '/:lang/admin',
		name: 'admin',
		redirect:to => {
			return {name:"admin:users", params:{lang:to.params.lang}}
		},
		component: Admin,
		meta:{
			isAdmin:true,
			needAuth:true,
			scrollable:true,
			fullWidth:true,
		},
		children: [
			{
				path: "users",
				name: "admin:users",
				components: {
					admin:AdminUsers
				},
			},
			{
				path: "games",
				name: "admin:games",
				components: {
					admin:AdminGames
				},
			},
			{
				path: "leaderboard",
				name: "admin:leaderboard",
				components: {
					admin:AdminLeaderBoard
				},
			},
			{
				path: "bledevice",
				name: "admin:bledevice",
				components: {
					admin:AdminBLEDevice
				},
			},
			{
				path: "labels",
				name: "admin:labels",
				components: {
					admin:AdminLabels
				},
			},
			{
				path: "contact",
				name: "admin:contact",
				redirect:to => {
					return {name:"admin:contact:mailing", params:{lang:to.params.lang}}
				},
				components: {
					admin:AdminContact
				},
				children: [
					{
						path: "rent",
						name: "admin:contact:rent",
						components: {
							contact:AdminRent
						},
					},
					{
						path: "mailing",
						name: "admin:contact:mailing",
						components: {
							contact:AdminMailing
						},
					},
					{
						path: "discord",
						name: "admin:contact:discord",
						components: {
							contact:AdminDiscordInvites
						},
					}
				]
			},
		],
	},
	{
		path: "/:lang/print/:uid/:gid",
		name: "admin:print",
		props:true,
		meta:{
			isAdmin:true,
			fullSize:true,
			scrollable:true,
			noBrowserCheck:true
		},
		component: AdminPrint,
	},
	{
		path: '/:lang/talkie/:emitter/:receiver',
		name: 'talkie',
		props:true,
		meta:{
			isHome:true,
			scrollable:true,
			noBrowserCheck:true,
			needGameData:false,
			sockEnabled:true,
			advertisePresence:true,
		},
		component: WalkieTalkieTest,
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router



function lazyLoadView(AsyncView) {
	const AsyncHandler = () => ({
	  component: AsyncView,
	  // A component to use while the component is loading.
	  loading: require('@/views/asyncroutes/Loading.vue').default,
	  // Delay before showing the loading component.
	  // Default: 200 (milliseconds).
	  delay: 0,
	  // A fallback component in case the timeout is exceeded
	  // when loading the component.
	  error: require('@/views/asyncroutes/Timeout.vue').default,
	  // Time before giving up trying to load the component.
	  // Default: Infinity (milliseconds).
	  timeout: 10000,
	})
  
	return Promise.resolve({
	  functional: true,
	  render(h, { data, children }) {
		// Transparently pass any props or children
		// to the view component.
		return h(AsyncHandler, data, children)
	  },
	})
  }