






















































import Button from '@/components/Button.vue';
import ContactForm from '@/components/ContactForm.vue';
import ContactInfos from '@/components/ContactInfos.vue';
import MatesList from '@/components/MatesList.vue';
import MessageWritter from '@/components/MessageWritter.vue';
import Persona from '@/components/Persona.vue';
import SkypeCall from '@/components/SkypeCall.vue';
import BleDeviceHelper from '@/utils/BleDeviceHelper';
import Utils from '@/utils/Utils';
import WalkieTalkie from '@/utils/WalkieTalkie';
import gsap from 'gsap';
import IGame from 'src_back/db/models/game/IGame';
import IUser from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import EndEnigmaForm from '../enigma/EndEnigmaForm.vue';

@Component({
	components:{
		Button,
		Persona,
		MatesList,
		SkypeCall,
		ContactForm,
		ContactInfos,
		EndEnigmaForm,
		MessageWritter,
	}
})
export default class Dialog extends Vue {

	@Prop()
	public messageData:{t:string,pa:string,a:string,e:string,s:string,n:string,u:string}

	@Prop({default:"game.dialogs."})
	public labelRoot:string;

	public currentData:any;
	public labelRoot_local:string;
	public effect:string = "";
	public message:string = "";
	public speaker:string = "";
	public isAlien:boolean = true;
	public dialogIndex:number = 0;
	public currentStep:string = "";
	public currentAction:string = "";
	public noSkip:boolean = false;
	public showNextBt:boolean = false;
	public showPrevBt:boolean = false;
	public showReload:boolean = false;
	public connectingBluetooth:boolean = false;
	public showMates:boolean = false;
	public audio:HTMLAudioElement;

	private clickHandler:any;

	public get classes():string[] {
		let res = ["dialog"]
		if(this.speaker == "me") res.push("intro")
		if(this.showMates) res.push("showMates")
		return res;
	}

	public mounted():void {
		this.onRouteChange();
		this.clickHandler = (e:MouseEvent) => this.fastForward();
		document.body.addEventListener("click", this.clickHandler);

		if(this.$refs.persona) {
			gsap.set((<Vue>this.$refs.persona).$el, {filter:"brightness(0)"});
			gsap.to((<Vue>this.$refs.persona).$el, 1.5, {filter:"brightness(1)", ease:"sine.in"});
		}
	}

	public beforeDestroy():void {
		if(this.audio) {
			this.audio.pause();
		}
		document.body.removeEventListener("click", this.clickHandler);
	}

	@Watch("messageData")
	public onMessageChange():void {
		this.onRouteChange();
	}

	@Watch("$route.params.step")
	public onRouteChange(resetDialogIndex:boolean = true):void {
		this.noSkip = false;
		this.showNextBt = false;
		this.showPrevBt = false;
		this.showMates = false;
		this.showReload = false;
		this.currentAction = null;
		this.currentStep = this.$route.params.step;
		if(resetDialogIndex) {
			this.dialogIndex = 0;
		}
		
		let lr = Utils.getRouteMetaValue(this.$route, "labelRoot")
		if(lr) {
			this.labelRoot_local = lr;
		}else{
			this.labelRoot_local = this.labelRoot;
		}

		let key = this.labelRoot_local+this.currentStep+"."+this.dialogIndex;
		this.currentData = this.$t(key);
		let mess = this.$t(key+".t").toString();//Do not use "this.currentData.t" here or postTranslation won't be executed to parse gender related stuff

		//Do not use $te as it's fucked up when it fallbacks to default language (returns false)
		// if(!this.$te(key)) {
		if(typeof this.currentData != "object") {
			this.currentData =  {u:"me", t:"dialog not found"};
			mess = this.currentData.t;
		}
		if(this.messageData) {
			this.currentData = this.messageData;
			mess = this.currentData.t;//WARNING genderification won't work here
		}
		
		this.speaker = this.currentData.u.toString();
		this.isAlien = this.speaker == "alien";
		if(this.$store.state.user) {
			let u:IUser = this.$store.state.user;
			let step1_d:number = new Date(u.key1Inserted_at).getTime() - new Date(u.frequencyFound_at).getTime();
			let box1Duration = Utils.durationToText(step1_d);
			let box1Duration_extended = Utils.durationToText(step1_d * 200);
			this.message = mess.replace(/	/gi, "")
			.replace(/\{USER\}/gi, this.$store.state.user.nickname)
			.replace(/\{USER_UPPER\}/gi, this.$store.state.user.nickname.toUpperCase())
			.replace(/\{GOVERNMENT\}/gi, this.getTeamMateGovernment())
			.replace(/\{TEAMMATE\}/gi, Utils.getTeamMate().nickname)
			.replace(/\{STEP1_DURATION\}/gi, box1Duration)
			.replace(/\{STEP1_DURATION_EXTENDED\}/gi, box1Duration_extended)
			.replace(/\n|\r/gi, "<br>");
		}else{
			this.message = mess;
		}
		if(this.currentData.e != undefined) {
			this.effect = this.currentData.e.toString();
		}else{
			this.effect = null;
		}

		this.playSound(key);

		if(this.currentData.pa) {
			this.parseAction( this.currentData.pa.toString() );
			// return;
		}

		if(this.message.length == 0) {
			this.messageComplete(false);
		}
	}

	private getTeamMateGovernment():string {
		let mate = Utils.getTeamMate();
		if(mate) return mate.government;
		return "";
	}

	public messageComplete(showNext:boolean = true):void {
		//if BLE connexion failed and user is asked to reload the page, stop there
		if(this.showReload) return;

		this.$emit("complete");
		
		//set prev/next buttons visibilities
		let key = this.labelRoot_local+this.currentStep+"."+(this.dialogIndex+1);
		//Force "en" locale as that's the only language i'm sure the all the labels exists so far
		this.showNextBt = (this.$te(key+".t", "en") || (this.currentData && this.currentData.n != "" && this.currentData.n != undefined)) && (!this.messageData || this.messageData.n != undefined);
		this.showPrevBt = this.dialogIndex > 0;

		//Is there an action to perform after message ?
		if(this.currentData.a) {
			this.parseAction( this.currentData.a );
			return;
		}
	}

	public onClickPrev():void {
		if(this.dialogIndex > 0) {
			this.dialogIndex --;
			this.onRouteChange(false);
		}
	}

	public onClickNext():void {
		let key = this.labelRoot_local+this.currentStep+"."+this.dialogIndex;
		if(this.currentData.n) {
			let next = this.currentData.n;
			if(next.params) {
				next.params.lang = this.$store.state.lang
			}
			this.dialogIndex = 0;
			this.$router.push(next);
			return;
		}else{
			this.dialogIndex ++;
		}
		this.onRouteChange(false);
	}

	public fastForward():void {
		if(this.noSkip) return;
		let key = this.labelRoot_local+this.currentStep+"."+(this.dialogIndex+1);

		(<MessageWritter>this.$refs.writter).skipAnimation();
	}

	private parseAction(action:string):void {
		this.currentAction = action;
		switch(action) {
			case "connectDevice":
				this.showNextBt = false;
				break;
			case "contactInfos":
				break;
			case "contactForm":
				this.showNextBt = false;
				break;
			case "skypecall":
				// this.showMates = true;
				break;
			case "showMates":
				this.showMates = true;
				break;
			case "requestMicAccess":
				this.showNextBt = false;
				break;
			case "cubes":
				break;
			case "kill_scientist":
				let a = new Audio(require('@/assets/sounds/gunshot.mp3'));
				a.play();
				this.speaker = "scientist_dead";
				break;
			case "end":
				break;
			case "noSkip":
				this.noSkip = true;
				break;
		}
	}

	public async onClickConnect():Promise<void> {
		if(this.connectingBluetooth) return;

		this.connectingBluetooth = true;
		try {
			await BleDeviceHelper.instance.connect(true);
		}catch(error) {
			if(error.message.indexOf("cancel") > -1) {
				this.connectingBluetooth = false;
			}else{
				this.showReload = true;
				this.showNextBt = false;
				this.showPrevBt = false;
				this.connectingBluetooth = false;
				this.message = this.$t("errors.connectFailed").toString();
			}
			return;
		}
		this.showReload = false;
		this.onClickNext();
	}

	public onClickReload():void {
		document.location.reload();
	}

	public onClickMicAccess():void {
		WalkieTalkie.requestMicAccess().then(_=> {
			this.onClickNext();
		});
	}

	public playSound(key:string):void {
		if(this.currentData.s != undefined) {
			let index = parseInt(this.currentData.s);
			if(!isNaN(index)) {
				BleDeviceHelper.instance.playMusic(index);
			}
		}

		if(this.audio) this.audio.pause();

		if(this.speaker == "scientist_glitch") {
			this.audio = new Audio(require('@/assets/sounds/glitch_sound.mp3'));
			// this.audio.loop = true;
			this.audio.volume = .3;
			this.audio.play();
		}
	}

	public fadeEnter(el, done):void {
		gsap.set(el, {opacity:0});
		gsap.to(el, .5, {opacity:1, onComplete:_=>done()});
	}

	public fadeBeforeLeave(el):void {
		gsap.set(el, {opacity:0});
	}
}
