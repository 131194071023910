







































































import Button from '@/components/Button.vue';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import {
	Component,
	Inject,
	Model,
	Prop,
	Vue,
	Watch,
	Provide,
} from "vue-property-decorator";

@Component({
	components: {
		Button,
	},
})
export default class NoBLE extends Vue {

	public copied:boolean = false;
	public ios:boolean = false;
	public android:boolean = false;
	public windows:boolean = false;
	public mac:boolean = false;
	public viewAllOptions:boolean = false;

	public get copyIcon():string {
		if(!this.copied) {
			return require('@/assets/icons/copy.svg');
		}else{
			return require('@/assets/icons/checkmark_dark.svg');
		}
	}
	public get url():string {
		if(this.copied) return this.$t('global.copied').toString();
		if(!this.$route.query.url) return null;
		return document.location.origin + this.$route.query.url;
	}

	public mounted(): void {
		let res = Utils.getDeviceAndBrowser();
		this.ios = Utils.isIOs();
		this.android = Utils.isAndroid();
		this.windows = Utils.isWindows();
		this.mac = Utils.isMac();
	}

	public beforeDestroy(): void {}

	public copy():void {
		Utils.copyToClipboard(this.url);
		let form = this.$refs.copyForm;
		gsap.set(form, {filter: "brightness(1)"});
		gsap.from(form, {duration:.5, filter: "brightness(5)"});
		this.copied = true;
		setTimeout(_=> {
			this.copied = false;
		}, 1000);
	}
}
