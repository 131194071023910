import { EventDispatcher } from '@/utils/EventDispatcher';
import * as SockJS from "sockjs-client";
import Config from "../utils/Config";
import SocketEvent from "../vo/SocketEvent";
import store from '@/store';
import IUser from 'src_back/db/models/user/IUser';

/**
 * Created by FDursus on 28/03/2019
 */

export default class SockController extends EventDispatcher {

	private static _instance: SockController;

	private _sockjs: any;
	private _timeout: number;
	private advertTimeout: number;
	private _pingInterval: number;
	private _attempts: number;
	private _user: IUser;
	private _mates: string[];
	private _connected : boolean = false;
	private _doAdvertise : boolean = false;

	constructor() {
		super();
		this.initialize();
	}

	/********************
	 * GETTER / SETTERS *
	 ********************/
	static get instance(): SockController {
		if (!SockController._instance) SockController._instance = new SockController();
		return SockController._instance;
	}

	public set mates(mates:string[]) {
		this._mates = mates;
	}

	public set user(user:IUser) {
		if(!user) return;
		this._user = user;
		// console.log("SET USER");
		this.sendMessage(SOCK_ACTIONS.DEFINE_UID, user._id);
	}



	/******************
	 * PUBLIC METHODS *
	 ******************/

	public connect() {
		if(this._connected) return;
		// console.log("CONNECT");
		this._sockjs = new SockJS(Config.SOCKET_PATH);
		this._sockjs.onclose = ()=> this.onClose();
		this._sockjs.onmessage = (message:string)=> this.onMessage(message);
		this._sockjs.onopen = ()=> this.onConnect();
		
		window.addEventListener('beforeunload', _=> this.disconnect());
	}
	
	public disconnect() {
		// console.log("DISCONNECT");
		if(this._connected) {
			// console.log("SAY I LEAVE ")
			this.sendMessage(SOCK_ACTIONS.LEAVE_ROOM, {user:this._user});
		}
		this._connected = false;
		clearTimeout(this._timeout);
		this._timeout = <any>setTimeout(_=> this._sockjs.close(), 500);
	}

	public startAdvertisement():void {
		// console.log("START ADVERT");
		this._doAdvertise = true;
		this.advertisePresence();
	}

	public stopAdvertisement():void {
		// console.log("STOP ADVERT");
		this._doAdvertise = false;
		clearTimeout(this._timeout);
	}

	public sendMessage(action:string, data?:any, excludeSelf:boolean = true):void {
		if(!this._connected) {
			//Postpone
			setTimeout(_=>{ this.sendMessage(action, data, excludeSelf); }, 1000);
			return;
		}
		let json = {
			action,
			data,
			to:this._mates
		};
		// console.log("send to", this._mates)
		if(excludeSelf && json.to) {
			for (let i = 0; i < json.to.length; i++) {
				if(json.to[i] == this._user._id) {
					json.to.splice(i, 1);
				}
			}
		}
		this._sockjs.send(JSON.stringify(json));
	}
	


	/*******************
	 * PRIVATE METHODS *
	 *******************/
	/**
	 * Initializes the class
	 */
	private initialize(): void {
	}

	private onConnect():void {
		this._connected = true;
		this._attempts = 0;

		if(this._user) {
			this.sendMessage(SOCK_ACTIONS.DEFINE_UID, this._user._id);
		}
		// console.log("ON CONNECT")
		
		clearInterval(<any>this._pingInterval);
		this._pingInterval = <any>setInterval(_=>this.ping(), 5000);
	}

	private ping():void {
		this._sockjs.send(JSON.stringify(SOCK_ACTIONS.PING));
	}

	private onClose():void {
		this._connected = false;
		clearInterval(<any>this._pingInterval);
		// if(++this._attempts == 10) return;
		this._timeout = <any>setTimeout(_=> this.connect(), 10000);
	}

	private onMessage(message:any):void {
		let json:any = JSON.parse(message.data);
		// console.log("Sock message");
		// console.log(json);
		switch(json.action) {
			case SOCK_ACTIONS.JOIN_ROOM:
				// console.log("User joined !");
				store.dispatch("userJoined", json.data);
				if(json.data.dontAnswer !== true) {
					// console.log("Answer to advertisement")
					this.advertisePresence(true);
				}
				break;
			case SOCK_ACTIONS.LEAVE_ROOM:
				store.dispatch("userLeft", json.data);
				break;
			case SOCK_ACTIONS.CURRENT_STEP:
				store.dispatch("userStep", json.data);
				break;
			case SOCK_ACTIONS.SEND_MESSAGE:
				store.dispatch("onChatMessage", json.data);
				break;
		}
		this.dispatchEvent(new SocketEvent(json.action, json.data));
	}

	private advertisePresence(isAnswer:boolean = false):void {
		// console.log("Advertise", this._doAdvertise);
		if(!this._doAdvertise) return;
		
		clearTimeout(this.advertTimeout);
		this.advertTimeout = setTimeout(_=>this.advertisePresence(), 10000);
		
		// console.log(this._mates, this._user._id)
		if(!this._mates || !this._user._id) return;

		let data:any = {uid:this._user._id};
		if(isAnswer) data.dontAnswer = true;
		// console.log("ADVERTISE !");
		SockController.instance.sendMessage(SOCK_ACTIONS.JOIN_ROOM, data);
	}
}

export enum SOCK_ACTIONS {
	PING="PING",
	DEFINE_UID="DEFINE_UID",
	JOIN_ROOM="JOIN_ROOM",
	LEAVE_ROOM="LEAVE_ROOM",
	CURRENT_STEP="CURRENT_STEP",
	SEND_MESSAGE="SEND_MESSAGE",
	AUDIO_MESSAGE="AUDIO_MESSAGE",
	SIDE_ACTIVATED="SIDE_ACTIVATED",
	SIDE_DEACTIVATED="SIDE_DEACTIVATED",
	MUTE="MUTE",
	UNMUTE="UNMUTE",
};