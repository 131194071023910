












import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import { IUserContact } from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';
import CirclesRendererCanvas from './CirclesRendererCanvas.vue';

@Component({
	components:{
		Button,
		CirclesRendererCanvas,
	}
})
export default class ContactInfos extends Vue {

	public loading:boolean = true;
	public error:boolean = false;
	public mail:string = null;
	public phone:string = null;
	public other:string = null;

	public async mounted():Promise<void> {
		
		//Get teammate contact infos and redirect accordingly
		let game = this.$store.state.game;
		let mate = Utils.getTeamMate();
		let data = {
			uid:mate._id,
			gid:game._id,
		}
		let res;
		try {
			res = await Api.get("user/contact", data);
		}catch(error) {
			res = null;
			console.log(error)
			this.error = true;
		}

		if(res && res.success) {
			let contactInfos = res.contact;
			if(contactInfos &&
			(contactInfos.mail || contactInfos.phone || contactInfos.other)) {
				this.mail = contactInfos.mail;
				this.phone = contactInfos.phone;
				this.other = contactInfos.other;
				this.$emit("contactAvailable");
			}else{
				this.$emit("noContactAvailable");
			}
		}
		this.loading = false;
	}

	public beforeDestroy():void {
		
	}

	private populate(data:IUserContact):void {
		this.mail = data.mail;
		this.phone = data.phone;
		this.other = data.other;
	}

}
