
































import Button from '@/components/Button.vue';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import WalkieTalkie from "@/utils/WalkieTalkie";
import Peer2peerHelper, { MediaConnection } from '@/utils/Peer2peerHelper';
import BleDeviceHelper, { BleEvent, SoundEffect } from '@/utils/BleDeviceHelper';
import SockController, { SOCK_ACTIONS } from '@/controller/SockController';
import SocketEvent from '@/vo/SocketEvent';
import MatesList from '@/components/MatesList.vue';
import CirclesRendererCanvas from '@/components/CirclesRendererCanvas.vue';
import FallbackFeatures from './FallbackFeatures.vue';
import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
@Component({
	components:{
		Button,
		MatesList,
		FallbackFeatures,
		CirclesRendererCanvas,
	}
})
export default class GameCollaboration extends Vue {

	@Prop()
	public peerid:string;

	@Prop()
	public remotepeerid:string;

	public closed:boolean = false;
	public cubePlaced:boolean = false;
	public mateReady:boolean = false;
	public mateConnected:boolean = false;
	public disposed:boolean = false;
	public connected:boolean = false;
	public loading:boolean = true;
	public muteRemote:boolean = true;
	public showAvatars:boolean = false;
	public animationFramesStep2:number = 0;
	public animationFramesStep2Max:number = 100;
	public animationFramesClose:number = 0;
	public animationFramesCloseMax:number = 250;
	public circlesCount:number = 32;
	public spectrumData1:{a:number, s:number}[] = [];
	public spectrumData2:{a:number, s:number}[] = [];

	private maxV:number = -9999;
	private talkie:WalkieTalkie;
	private muteHandler:Function;
	private readyHandler:Function;
	
	public get topState():boolean { return BleDeviceHelper.instance.topValue; }
	public get backState():boolean { return BleDeviceHelper.instance.backValue; }
	public get rightState():boolean { return BleDeviceHelper.instance.rightValue; }
	public get irBackValue():number { return BleDeviceHelper.instance.irBackValue; }
	public get isSecondStep():boolean { return this.$route.name=="game:collabpart2"; }
	public get cubeSide():string { return this.$store.state.user.side; }

	public get endClasses():string[] {
		let res = ["circleEnd"];
		if(!this.closed) res.push("close");
		return res;
	}
	
	public async mounted():Promise<void> {
		this.loading = true;
		await Peer2peerHelper.instance.connect(this.peerid, this.remotepeerid);
		this.talkie = new WalkieTalkie(()=> {
			Peer2peerHelper.instance.on("data", this.dataHandler);
			this.startCapture();
		}, ()=> {
			this.mateConnected = true;
			this.topStateChanged();
		});

		for (let i = 0; i < this.circlesCount; i++) {
			let a = Math.PI*2*Math.random()*.1;
			let s = -a/2 - Math.PI/2;
			this.spectrumData1.push({a, s});
			this.spectrumData2.push({a, s});
		}

		this.loading = false;
		this.showAvatars = true;

		this.muteHandler = (e:SocketEvent) => this.onMuteChange(e);
		this.readyHandler = (e:SocketEvent) => this.onMateReadyChange(e);
		SockController.instance.addEventListener(SOCK_ACTIONS.MUTE, this.muteHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.UNMUTE, this.muteHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.SIDE_ACTIVATED, this.readyHandler);
		SockController.instance.addEventListener(SOCK_ACTIONS.SIDE_DEACTIVATED, this.readyHandler);

		this.renderFrame();
		this.onRouteChanged();
		
		await Utils.saveTimestamp("collabStart");
	}

	public beforeDestroy():void {
		this.disposed = true;
		this.talkie.stop();
		Peer2peerHelper.instance.disconnect();
		SockController.instance.removeEventListener(SOCK_ACTIONS.MUTE, this.muteHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.UNMUTE, this.muteHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.SIDE_ACTIVATED, this.readyHandler);
		SockController.instance.removeEventListener(SOCK_ACTIONS.SIDE_DEACTIVATED, this.readyHandler);
	}

	public startCapture():void {
		this.talkie.start();
		this.talkie.mute();
		this.connected = true;
	}

	public stopCapture():void {
		this.talkie.stop();
		this.connected = false;
		// this.talkie.stop();
	}

	public dataHandler(conn:MediaConnection):void {
		// console.log("ON DATA RECEIVED")
	}

	@Watch("topState")
	public topStateChanged():void {
		// if(this.topState) {
		// 	this.talkie.mute();
		// }else{
		// 	this.talkie.unmute();
		// }
		if(this.topState && this.mateConnected) {
			BleDeviceHelper.instance.playMusic(SoundEffect.TALKIE_ORGANIC);
		}
		let a = !this.topState? SOCK_ACTIONS.MUTE : SOCK_ACTIONS.UNMUTE;
		// console.log("Tell remote to ", a)
		SockController.instance.sendMessage(a);
	}

	@Watch("backState")
	public backStateChanged():void {
		if(this.cubeSide != "back") return;
		this.cubePlaced = this.backState;
		let a = this.backState? SOCK_ACTIONS.SIDE_ACTIVATED : SOCK_ACTIONS.SIDE_DEACTIVATED;
		//Tell our mate we're ready !
		SockController.instance.sendMessage(a);
		BleDeviceHelper.instance.playMusic(SoundEffect.TALKIE_ORGANIC);
		this.redirectIfBothReady();
	}

	@Watch("rightState")
	public rightStateChanged():void {
		if(this.cubeSide != "right") return;
		this.cubePlaced = this.rightState;
		let a = this.rightState? SOCK_ACTIONS.SIDE_ACTIVATED : SOCK_ACTIONS.SIDE_DEACTIVATED;
		//Tell our mate we're ready !
		SockController.instance.sendMessage(a);
		BleDeviceHelper.instance.playMusic(SoundEffect.TALKIE_ORGANIC);
		this.redirectIfBothReady();
	}

	@Watch("irBackValue")
	public async irBackValueChanged():Promise<void> {
		if(this.irBackValue > 50 || !this.isSecondStep || this.animationFramesClose > 0) return;
		this.animationFramesClose = this.animationFramesCloseMax;
		BleDeviceHelper.instance.playMusic(SoundEffect.INSERT_KEY);
		setTimeout(_=> {
			BleDeviceHelper.instance.playMusic(SoundEffect.AMBIANT_MURMURS, true);
			// BleDeviceHelper.instance.setVolume(70);
		}, 2000);
		await Utils.saveTimestamp("discInserted");
	}

	@Watch("$route")
	public onRouteChanged():void {
		if(this.isSecondStep) {
			BleDeviceHelper.instance.openSecretCompartment();
		}
		if(this.isSecondStep) {
			this.animationFramesStep2 = this.animationFramesStep2Max;
		}
	}

	private onMateReadyChange(e:SocketEvent):void {
		this.mateReady = e.getType() == SOCK_ACTIONS.SIDE_ACTIVATED;
		this.redirectIfBothReady();
	}

	private async redirectIfBothReady():Promise<void> {
		if(this.cubePlaced && this.mateReady) {
			await Utils.saveTimestamp("cubesPlaced");
			//Redirect to second step to open the box
			this.$router.push({name:"game:collabpart2"});
		}
	}

	private onMuteChange(e:SocketEvent):void {
		switch(e.getType()) {
			case SOCK_ACTIONS.MUTE:
				// console.log("MUTE remote");
				this.muteRemote = true;
				this.talkie.mute();
				break;
			case SOCK_ACTIONS.UNMUTE:
				// console.log("UNMUTE remote");
				this.muteRemote = false;
				this.talkie.unmute();
				break;
		}
	}

	private renderFrame():void {
		if(this.disposed) return;
		requestAnimationFrame(_ => this.renderFrame());
		let spectrumEmitter = [];
		let spectrumReceiver = [];

		if(this.topState) {
			let step = Math.floor(this.talkie.spectrumDataEmitter.length / this.circlesCount);
			for (let i = 0; i < this.talkie.spectrumDataEmitter.length; i+=step) {
				// spectrumEmitter.push((this.talkie.spectrumDataEmitter[i] - 128)/128 * 200);
				this.maxV = Math.max(this.talkie.spectrumDataEmitter[i], this.maxV)
				let a = Math.abs(this.talkie.spectrumDataEmitter[i] - 128)/80 * Math.PI;
				a = Math.min(a, Math.PI * .9);
				let s = -a/2 - Math.PI/2;
				spectrumEmitter.push({a, s});
			}
		}
		// console.log(this.maxV);
			
		if(!this.muteRemote) {
			let step = Math.floor(this.talkie.spectrumDataEmitter.length / this.circlesCount);
			for (let i = 0; i < this.talkie.spectrumDataReceiver.length; i+=step) {
				// spectrumReceiver.push((this.talkie.spectrumDataReceiver[i] - 128)/128 * 200);
				let a = Math.abs(this.talkie.spectrumDataReceiver[i] - 128)/80 * Math.PI;
				a = Math.min(a, Math.PI * .9);
				let s = -a/2 + Math.PI/2;
				spectrumReceiver.push({a, s});
			}
		}

		//animation when both players place the cube properly
		//animation overrides the sound spectrums
		if(this.animationFramesStep2 > 0) {
			this.animationFramesStep2--;
			spectrumEmitter = [];
			spectrumReceiver = [];
			for (let i = 0; i < this.circlesCount; i++) {
				let r = 1-this.animationFramesStep2/this.animationFramesStep2Max;
				let a = (Math.sin(i/(this.circlesCount/(2*(1-r))) * Math.PI*2)+1)/2 * Math.PI;
				a *= 1-(Math.cos(r*Math.PI*2)+1)/2;
				let s = -a/2 - Math.PI/2 * (r*8);
				spectrumEmitter.push({a, s});
				spectrumReceiver.push({a, s:s-Math.PI});
			}
		}

		//Closing animation when inserting disk
		//animation overrides other animations
		if(this.animationFramesClose >= 0) {
			this.animationFramesClose--;
			spectrumEmitter = [];
			spectrumReceiver = [];
			let r = 1-this.animationFramesClose/this.animationFramesCloseMax;
			let steps = 15;
			
			let f = this.animationFramesCloseMax - this.animationFramesClose;
			let add = (this.animationFramesCloseMax - this.animationFramesClose)*(r+.25)*.5 + this.circlesCount;
			
			for (let i = 0; i < this.circlesCount; i++) {
				let a = Math.round(add-i)%steps<steps/2? Math.PI*r : 0;
				if(i > add-this.circlesCount) a = 0;
				
				let s = -a/2 - Math.PI/2;
				spectrumEmitter.push({a, s});
				spectrumReceiver.push({a, s:s-Math.PI});
			}
			if(this.animationFramesClose == this.animationFramesCloseMax*.1) {
				this.close();
			}
		}
		
		let users = this.$store.state.game.users;
		if(users[0]._id == this.$store.state.user._id) {
			this.spectrumData1 = spectrumEmitter;
			this.spectrumData2 = spectrumReceiver;
		}else{
			this.spectrumData1 = spectrumReceiver;
			this.spectrumData2 = spectrumEmitter;
		}
	}

	private close():void {
		this.closed = true;
		setTimeout(_=> {
			//Redirect to end of game
			this.$router.push({name:"game:d", params:{step:"gameComplete", lang:this.$store.state.lang}});
		}, 1000);
	}

}
