



















import Button from '@/components/Button.vue';
import SockController, { SOCK_ACTIONS } from '@/controller/SockController';
import BleDeviceHelper from '@/utils/BleDeviceHelper';
import IUser from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		Button,
	}
})
export default class FallbackFeatures extends Vue {

	@Prop({default:false})
	public crystal:boolean;
	@Prop({default:false})	
	public cubeTop:boolean;
	@Prop({default:false})	
	public cubeBack:boolean;
	@Prop({default:false})	
	public cubeRight:boolean;
	@Prop({default:false})	
	public disk:boolean;
	
	public showContent:boolean = false;
	public reedTopDown:boolean = false;
	public reedRightDown:boolean = false;
	public reedBackDown:boolean = false;
	public irFrontDown:boolean = false;
	public irBackDown:boolean = false;

	public get showIrFront():boolean { return this.crystal !== false && (this.irFrontDown || this.enableAll); }
	public get showIrBack():boolean { return this.disk !== false && (this.irBackDown || this.enableAll); }
	public get showCubeTop():boolean { return this.cubeTop !== false && (this.reedTopDown || this.enableAll); }
	public get showCubeBack():boolean { return this.cubeBack !== false && (this.reedBackDown || this.enableAll); }
	public get showCubeRight():boolean { return this.cubeRight !== false && (this.reedRightDown || this.enableAll); }
	public get enableAll():boolean { return this.$route.query.nbc !== undefined; }

	public get anyAction():boolean {
		return this.showIrFront || this.showIrBack || this.showCubeTop || this.showCubeBack || this.showCubeRight || this.enableAll;
	}

	public mounted():void {
		let u:IUser = this.$store.state.user;
		this.reedTopDown = u.emulateReedTop;
		this.reedRightDown = u.emulateReedRight;
		this.reedBackDown = u.emulateReedBack;
		this.irFrontDown = u.emulateIrFront;
		this.irBackDown = u.emulateIrBack;
	}

	public beforeDestroy():void {
		
	}

	private onClick(action:string):void {
		switch(action) {
			case "reedTop":
				BleDeviceHelper.instance.topValue = true;
				break;
			case "reedNotTop":
				BleDeviceHelper.instance.topValue = false;
				break;
			case "reedBack":
				BleDeviceHelper.instance.backValue = true;
				break;
			case "reedRight":
				BleDeviceHelper.instance.rightValue = true;
				break;
			case "irFront":
				BleDeviceHelper.instance.irFrontValue = 0;
				break;
			case "irBack":
				BleDeviceHelper.instance.irBackValue = 0;
				break;
		}
	}

	public toggle():void {
		this.showContent = !this.showContent;
	}
}
