








import Utils from "@/utils/Utils";
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class FullscreenView extends Vue {

	public enabled:boolean = false;
	public isFullScreen:boolean = false;
	private fullScreenChangeHandler:any;

	public mounted():void {
		this.onRouteChange();
		
		this.fullScreenChangeHandler = (e)=>this.onFullScreenChange(e);
		document.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
	}

	public beforeDestroy():void {
		document.removeEventListener('fullscreenchange', this.fullScreenChangeHandler);
	}

	public onFullScreenChange(e:Event):void {
		this.isFullScreen = document.fullscreenElement != null;
	}

	@Watch("$route")
	public onRouteChange(resetDialogIndex:boolean = true):void {
		this.enabled = Utils.getRouteMetaValue(this.$route, "fullscreenEnabled") === true;
	}

	public goFullScreen():void {
		document.body.requestFullscreen().then(_=> {})
	}

}
