














import { Component, Inject, Model, Prop, Vue, Watch, Provide } from 'vue-property-decorator';
import gsap from 'gsap';
import Button from '@/components/Button.vue';

@Component({
	components:{
		Button
	}
})
export default class ConfirmView extends Vue {
	public title:string = "";
	public description:string = "";
	public yesLabel:string = "";
	public noLabel:string = "";
	public hidden:boolean = true;

	public mounted():void {
		document.addEventListener("keyup", (e:KeyboardEvent) => this.onKeyUp(e))
	}

	@Watch('$store.state.confirm', { immediate: true, deep: true })
	private onConfirmChanged() {
		let hidden = !this.$store.state.confirm || !this.$store.state.confirm.title;
		
		if(this.hidden == hidden) return;//No change, ignore

		if(!hidden) {
			this.hidden = hidden;
			this.title = this.$store.state.confirm.title;
			this.description = this.$store.state.confirm.description;
			this.yesLabel = this.$store.state.confirm.yesLabel || this.$t('global.yes').toString();
			this.noLabel = this.$store.state.confirm.noLabel || this.$t('global.no').toString();
			//@ts-ignore
			document.activeElement.blur();//avoid clicking again on focused button if submitting confirm via SPACE key
			gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
			gsap.set(this.$refs.holder, {marginTop:0, opacity:1});
			gsap.to(this.$refs.dimmer, {duration:.25, opacity:1});
			gsap.from(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out"});
		}else{
			gsap.killTweensOf([this.$refs.holder, this.$refs.dimmer]);
			gsap.to(this.$refs.dimmer, {duration:.25, opacity:0});
			gsap.to(this.$refs.holder, {duration:.25, marginTop:100, opacity:0, ease:"back.out", onComplete:()=> {
				this.hidden = true;
			}});
		}
	}

	private onKeyUp(e:KeyboardEvent):void {
		if(this.hidden) return;
		if(e.keyCode == 13 || e.keyCode == 32) {//Enter / space
			this.answer(true);
			e.preventDefault();
			e.stopPropagation();
		}
		if(e.keyCode == 27) {//escape
			this.answer(false);
			e.preventDefault();
			e.stopPropagation();
		}
	}

	private answer(confirm:boolean):void {
		if(!this.$store.state.confirm) return;
		
		if(confirm) {
			if(this.$store.state.confirm.confirmCallback) {
				this.$store.state.confirm.confirmCallback();
			}
		}else{
			if(this.$store.state.confirm.cancelCallback) {
				this.$store.state.confirm.cancelCallback();
			}
		}
		this.$store.state.confirm = null;
	}
}
