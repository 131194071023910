












































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import BleDeviceHelper, { BleEvent, SoundEffect } from '@/utils/BleDeviceHelper';
import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import AbstractBLEView from '../AbstractBLEView';
import Dialog from './Dialog.vue';
import Button from '@/components/Button.vue';
import gsap from 'gsap';
import FallbackFeatures from './FallbackFeatures.vue';
import Store from '@/store/Store';
import IUser from 'src_back/db/models/user/IUser';

@Component({
	components:{
		Button,
		Dialog,
		FallbackFeatures,
	}
})
export default class GameHints extends AbstractBLEView {

	@Prop({default:"box1"})
	public hintRootNodeId:string;

	public isFirstClick:boolean = true;
	public showActions:boolean = false;
	public transitionEnd:boolean = false;
	public messageComplete:boolean = false;
	public keyInserted:boolean = false;
	public messageString:string = "";
	public hints:string[] = null;
	public hintIndex:number = 0;
	public currentNode:any = null;
	public currentKey:string = null;
	public idToReadState:{ [id: string] : boolean; } = {};

	public get irFrontValue():number { return BleDeviceHelper.instance.irFrontValue; }

	public get message():any {
		return { u:"alien", t:this.messageString.toLowerCase() }
	}

	public get endClasses():string[] {
		let res = ["circleEnd"];
		if(!this.transitionEnd) res.push("close");
		return res;
	}

	public getHintClasses(h:any):string[] {
		let res = [];
		if(this.messageString == h) res.push("select");
		return res;
	}

	public isRead(index:number):boolean {
		return this.idToReadState[this.hintRootNodeId+"_"+this.currentKey+"_"+index] === true;
	}

	public async mounted():Promise<void> {
		super.mounted();
		//Restore read states from localstorage
		let states = (<IUser>this.$store.state.user).hintsReadStates;
		if(states) {
			this.idToReadState = states;
		}
		
		states = JSON.parse(Store.get("hintKeyToState"));
		if(states) {
			this.currentKey = states[this.hintRootNodeId];
		}
	}

	public beforeDestroy():void {
		
	}

	protected onDeviceConnect(e:BleEvent = null):void {
		BleDeviceHelper.instance.openBox();
		Utils.saveTimestamp("boxOpened");
	}

	public onMessageComplete():void {
		this.messageComplete = true;
		this.showActions = this.messageString != null && this.messageString.length > 0;
	}
	
	@Watch("messageString")
	public messageChange():void {
		this.messageComplete = false;
	}

	public getHint():void {
		if(!this.isFirstClick || (this.idToReadState != null && Object.keys(this.idToReadState).length>0)) {
			this.giveHint();
			return;
		}

		Utils.confirm(this.$t('hints.confirmTitle'),
		this.$t('hints.confirmDescription'),
		null,
		this.$t('global.getHint'),
		this.$t('global.cancel'))
		.then(_=> {
			this.isFirstClick = false;
			this.giveHint();
		}).catch(_=> { /*ignore*/ })
	}

    public beforeEnter(el) {
		gsap.set(el, {opacity:0, marginTop:"-20px"});
	}
	
    public enter(el, done) {
		var delay = parseInt(el.dataset.index) * .1;
		gsap.to(el, .5, {opacity:1, marginTop:0, delay, onComplete:_=> done()});
	}
	
    public leave (el, done) {
		this.showActions = false;
		done();
    }

	@Watch("irFrontValue")
	private async irValueChange():Promise<void> {
		if(this.irFrontValue < 100 && !this.keyInserted) {
			this.keyInserted = true;//Avoids duplicate calls of the following if wiggling key
			BleDeviceHelper.instance.playMusic(SoundEffect.INSERT_KEY);
			await Utils.promisedTimeout(500);
			this.transitionEnd = true;
			await Utils.saveTimestamp("keyInserted");
			await Utils.promisedTimeout(1000);
			this.$router.push({name:"game:d", params:{step:"box1Solved", lang:this.$store.state.lang}});
		}
	}

	private giveHint():void {
		this.currentNode = <any>this.$t("hints."+this.hintRootNodeId);
		if(!this.currentKey) this.currentKey = this.currentNode.startNode;
		this.messageString = this.currentNode[this.currentKey].question;
		this.hintIndex = 0;
		this.showActions = true;
		this.saveStateToLS();
	}

	private saveStateToLS():void {
		let data = JSON.parse(Store.get("hintKeyToState"));
		if(!data) data = {};
		data[this.hintRootNodeId] = this.currentKey;
		Store.set("hintKeyToState", JSON.stringify(data))
	}

	private answerNo():void {
		let node = this.currentNode[this.currentKey].no;
		this.parseNode(node);
	}

	private answerYes():void {
		let node = this.currentNode[this.currentKey].yes;
		this.parseNode(node);
	}

	private parseNode(node:any):void {
		if(node.action == "close") {
			this.closeHints();
		}else
		if(node.goTo) {
			this.currentKey = node.goTo;
			this.giveHint();
		}else{
			this.hints = node.hints;
			this.showHint(0);
		}
	}

	private answerCancel():void {
		this.showActions = false;
		this.messageString = "";
	}

	private showHint(index:number):void {
		this.messageString = this.hints[index];
		this.showActions = false;
		this.idToReadState[this.hintRootNodeId+"_"+this.currentKey+"_"+index] = true;
		let states = this.idToReadState;
		Api.post("user/hintsReadStates", {pid:this.$store.state.user.privateID, states});
	}

	private closeHints():void {
		this.hints = null;
		this.showActions = false;
		this.messageString = "";
	}

	private resetHints():void {
		Utils.confirm(this.$t('hints.reset.confirmTitle').toString(),
		this.$t('hints.reset.confirmDescription').toString())
		.then(_=> {
			this.currentKey = null;
			Store.remove("hintKeyToState");
			this.closeHints();
			// this.idToReadState = {};
			// Api.post("user/hintsReadStates", {pid:this.$store.state.user.privateID};
		}).catch(_=> { /*ignore*/ })
	}

}
