









import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from '@/components/Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class Timeout extends Vue {

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	private reload():void {
		window.location.reload();
	}

}
