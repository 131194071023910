



















import Api from '@/utils/Api';
import Config from '@/utils/Config';
import Utils from '@/utils/Utils';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class RentForm extends Vue {

	public loading:boolean = false;
	public email:string = "";
	public message:string = "";
	public error:string = "";

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public async onSubmit():Promise<void> {
		let token = await window.grecaptcha.execute(Config.RECAPTCHA_KEY, {action: 'rent'})
		
		this.error = null;
		this.loading = true;
		await Utils.promisedTimeout(150);
		
		let res;
		try {
			res = await Api.post("contact/rent", {mail:this.email, message:this.message, lang:this.$route.params.lang, token});
		}catch(error) {
			let message = this.$t("errors.rentFailed").toString();
			if(error.code == "INVALID_MAIL") {
				message = this.$t("errors.contactMail").toString();
			}
			if(error.code == "INVALID_MESSAGE") {
				message = this.$t("errors.rentMessage").toString();
			}
			if(error.code == "INVALID_CAPTCHA") {
				message = this.$t("errors.invalidCaptcha").toString();
			}
			this.loading = false;
			this.error = message;
			return;
		}

		if(res.success) {
			this.$emit("submit");
		}

		this.loading = false;
	}

}
