


















import Api from '@/utils/Api';
import Config from '@/utils/Config';
import Utils from '@/utils/Utils';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from './Button.vue';

@Component({
	components:{
		Button,
	}
})
export default class MailForm extends Vue {

	@Prop()
	public inviteCode:string;

	public loadingMail:boolean = false;
	public submitted:boolean = false;
	public email:string = null;
	public error:string = null;

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

	public async onSubmit():Promise<void> {
		let token = await window.grecaptcha.execute(Config.RECAPTCHA_KEY, {action: 'mail'})

		this.error = null;
		this.loadingMail = true;
		await Utils.promisedTimeout(150);
		
		let res;
		try {
			res = await Api.post("contact/mail", {mail:this.email, code:this.inviteCode, lang:this.$route.params.lang, token});
		}catch(error) {
			let message = this.$t("errors.contactFailed").toString();
			if(error.code == "INVALID_MAIL") {
				message = this.$t("errors.contactMail").toString();
			}
			if(error.code == "INVALID_CAPTCHA") {
				message = this.$t("errors.invalidCaptcha").toString();
			}
			this.loadingMail = false;
			this.error = message;
			return;
		}

		if(res.success) {
			this.submitted = true;
		}

		this.loadingMail = false;
	}

}
