import { render, staticRenderFns } from "./SkypeCall.vue?vue&type=template&id=f544550c&scoped=true&"
import script from "./SkypeCall.vue?vue&type=script&lang=ts&"
export * from "./SkypeCall.vue?vue&type=script&lang=ts&"
import style0 from "./SkypeCall.vue?vue&type=style&index=0&id=f544550c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f544550c",
  null
  
)

export default component.exports