import IUser from 'src_back/db/models/user/IUser';
import { Bluetooth, BluetoothDevice, BluetoothRemoteGATTCharacteristic, BluetoothRemoteGATTServer, BluetoothRemoteGATTService } from "../types/Bluetooth";
import Vue from 'vue';
import { Event, EventDispatcher } from './EventDispatcher';

/**
* Created : 09/09/2020 
*/
export default class BleDeviceHelper extends EventDispatcher {

	private static _instance:BleDeviceHelper;

	public uuid:string = null;
	public musicValue:number = null;
	public motorValue:number = null;
	public topValue:boolean = false;
	public backValue:boolean = false;
	public rightValue:boolean = false;
	public irFrontValue:number = 1023;
	public irBackValue:number = 1023;
	public irThreshold:number = 0;
	public connected:boolean = false;
	public executingACommand:boolean;
	public emulateReedTop:boolean = false;
	public emulateReedRight:boolean = false;
	public emulateReedBack:boolean = false;
	public emulateIrFront:boolean = false;
	public emulateIrBack:boolean = false;
	public emulateMotor:boolean = false;
	public emulateSound:boolean = false;

	private bleUuid = "4f2cb1d0-d600-40f0-8d23-94643bbb65ce";
	private musicService = "f20653df-d601-4c42-b43f-470c6a1c8ca8";
	private motorService = "f20653df-d602-4c42-b43f-470c6a1c8ca8";
	private reedTopService = "f20653df-d603-4c42-b43f-470c6a1c8ca8";
	private reedBackService = "f20653df-d604-4c42-b43f-470c6a1c8ca8";
	private reedRightService = "f20653df-d605-4c42-b43f-470c6a1c8ca8";
	private readStateService = "f20653df-d606-4c42-b43f-470c6a1c8ca8";
	private volumeService = "f20653df-d607-4c42-b43f-470c6a1c8ca8";
	private IRFrontService = "f20653df-d608-4c42-b43f-470c6a1c8ca8";
	private IRBackService = "f20653df-d609-4c42-b43f-470c6a1c8ca8";
	private irThresholdService = "f20653df-d60a-4c42-b43f-470c6a1c8ca8";
	private uuidService = "f20653df-d60b-4c42-b43f-470c6a1c8ca8";

	private ble:Bluetooth;
	private device:BluetoothDevice;
	private server:BluetoothRemoteGATTServer;
	private service:BluetoothRemoteGATTService;
	private characteristics:BluetoothRemoteGATTCharacteristic[];
	private manuallyDisconnected:boolean;
	private waitingForDeviceResolver:Function;

	private callStack:{service:string, value:any}[] = [];
	
	constructor() {
		super();
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():BleDeviceHelper {
		if(!BleDeviceHelper._instance) {
			BleDeviceHelper._instance = new BleDeviceHelper();
			BleDeviceHelper._instance.initialize();
		}
		return BleDeviceHelper._instance;
	}

	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public waitForDevice():Promise<any> {
		return new Promise((resolve, reject)=> {
			if(this.connected) resolve();
			else {
				this.waitingForDeviceResolver = resolve;
			}
		})
	}

	public openConnectionModal(connectReason:string):void {
		this.dispatchEvent(new BleEvent(BleEvent.OPEN_CONNECTION_MODAL, connectReason));
	}

	public connect(playSound:boolean = false):Promise<void> {
		this.dispatchEvent(new BleEvent(BleEvent.START_CONNECTING));
		this.characteristics = [];
		this.manuallyDisconnected = false;
		return new Promise(async (resolve, reject) => {
			try {
				if(!this.device) {
					this.device = await this.ble.requestDevice({ filters: [{ name: 'The Artifact' }], optionalServices: [this.bleUuid] });
					console.log("Got device", this.device);
					if(this.device) {
						this.dispatchEvent(new BleEvent(BleEvent.DEVICE_FOUND));
					}
				}
				if(!this.server) {
					this.server = await this.device.gatt.connect();
					console.log("Got server", this.server);
					if(this.server) {
						this.dispatchEvent(new BleEvent(BleEvent.SERVER_FOUND));
					}
				}

				this.service = await this.server.getPrimaryService(this.bleUuid);
				console.log("Got service", this.service);
			}catch(error) {
				console.log(error);
				if(error.message.indexOf("cancel") > -1) {
					this.disconnect();
					this.dispatchEvent(new BleEvent(BleEvent.CONNECT_CANCELED));
				}else{
					try {
						if(!this.server.connected || !this.device.gatt.connected) {
							console.log("Server already available, connect to it");
							await this.server.connect();
						}
						this.service = await this.server.getPrimaryService(this.bleUuid);
						console.log("Got service", this.service);
					}catch(error) {
						console.log("Failed twice, giveup !");
						console.log(error);
						this.dispatchEvent(new BleEvent(BleEvent.CONNECT_ERROR));
						reject(error);
						return;
					}
				}
			};
	
			//Sub to live updates of some BLE props
			try {
				await this.getNotifications(this.reedTopService, "top");
				await this.getNotifications(this.reedBackService, "back");
				await this.getNotifications(this.reedRightService, "right");
				await this.getNotifications(this.IRFrontService, "irFront");
				await this.getNotifications(this.IRBackService, "irBack");
			}catch(error) {
				if(this.device) {
					if(error.message.indexOf("cancel") > -1) {
						this.dispatchEvent(new BleEvent(BleEvent.CONNECT_CANCELED));
					}else{
						this.disconnect();
						this.dispatchEvent(new BleEvent(BleEvent.CONNECT_ERROR));
					}
				}
				reject(error);
				return;
			}
	
			var v = await this.readValue(this.uuidService);
			this.uuid = new TextDecoder("utf-8").decode(v.buffer);

			this.device.addEventListener('gattserverdisconnected', (e)=> {
				if(this.manuallyDisconnected) return;
				this.dispatchEvent(new BleEvent(BleEvent.DEVICE_LOST));
				this.disconnect();
			});

			this.connected = true;

			if(playSound) {
				BleDeviceHelper.instance.setVolume(100);
				BleDeviceHelper.instance.playMusic(SoundEffect.BLUETOOTH_ORGANIC);
				//Give it time to play the sound
				await new Promise(function (resolve) { setTimeout(_ => resolve(), 1200); })
			}
	
			await this.getState();

			if(this.device) {
				this.dispatchEvent(new BleEvent(BleEvent.DEVICE_CONNECTED));
			}
			resolve();
			if(this.waitingForDeviceResolver) {
				this.waitingForDeviceResolver();
			}

			// this.updateIrThreshold(10);
		})
	}

	public async disconnect():Promise<void> {
		this.connected = false;
		this.manuallyDisconnected = true;
		if(this.device) {
			try {
				if(this.device.gatt.connected) {
					for (let i = 0; i < this.characteristics.length; i++) {
						const c = this.characteristics[i];
						await c.stopNotifications();
					}
				}
				this.device.gatt.disconnect();
			}catch(e){ /* ignore */ console.log(e); }
		}
		
		if(this.server) {
			try {
				this.server.disconnect();
			}catch(e){ /* ignore */console.log(e); }
		}
		this.device = null;
		this.server = null;
		this.service = null;
	}

	/**
	 * Sets the volume value of the speaker
	 * @param percent 0-100
	 */
	public setVolume(percent:number):Promise<void> {
		return this.sendValue(this.volumeService, percent/100 * 30);
	}

	/**
	 * Plays a spectific track by its index
	 * @param index 
	 */
	public playMusic(index:number, loop:boolean = false):Promise<void> {
		var uint8 = new Uint8Array(3);
		uint8[0] = 0x0F;//Play a specific track from a specific folder
		uint8[1] = 1;//Folder index
		uint8[2] = index;//Track index
		let prom = this.sendValue(this.musicService, uint8);
		if(loop) {
			return this.loopMusic();
		}else{
			return prom;
		}
	}

	/**
	 * Stops the sound currently playing
	 */
	public stopMusic():void {
		var uint8 = new Uint8Array(3);
		uint8[0] = 0x16;
		uint8[1] = 0;
		uint8[2] = 0;
		this.sendValue(this.musicService, uint8);
	}

	/**
	 * Loops the currently playing music
	 */
	public loopMusic():Promise<void> {
		var uint8 = new Uint8Array(3);
		uint8[0] = 0x19;//Play a specific track from a specific folder
		uint8[1] = 0;
		uint8[2] = 0;
		return this.sendValue(this.musicService, uint8);
	}

	/**
	 * Opens the door of the box
	 */
	public openBox():void {
		this.playMusic(SoundEffect.OPEN_BOX);
		setTimeout(_=> {
			this.sendValue(this.motorService, 60);
		}, 700);
	}

	/**
	 * Open the secret compartment
	 */
	public openSecretCompartment():void {
		this.playMusic(SoundEffect.OPEN_BOX);
		setTimeout(_=> {
			this.sendValue(this.motorService, 110);
		}, 700);
	}

	/**
	 * Close the secret compartment
	 */
	public closeSecretCompartment():void {
		this.openBox();
	}

	/**
	 * Close the main door
	 */
	public closeBox():void {
		this.sendValue(this.motorService, 10);
	}

	/**
	 * Refresh the local data with the remote ones.
	 */
	public getState():Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				let v = await this.readValue(this.readStateService);
				//[music, motor, topState, backState, rightState, irFront, irBack, irThreshold]
				let values = new Uint8Array(v.buffer);
				if(!this.emulateReedTop) this.topValue = values[2] == 1;
				if(!this.emulateReedBack) this.backValue = values[3] == 1;
				if(!this.emulateReedRight) this.rightValue = values[4] == 1;
				if(!this.emulateIrFront) this.irFrontValue = values[5];
				if(!this.emulateIrBack) this.irBackValue = values[6];
				this.irThreshold = values[7];
				resolve();
			}catch(error) {
				reject(error);
			}
		});
	}
	
	/**
	 * Updates the IR threshold.
	 * This value defines the change amount to be detected in order
	 * to fire a BLE change event of the ir sensor.
	 * @param value  0-255
	 */
	public updateIrThreshold(value:number) {
		value = Math.ceil(value/255 * 0xfff);
		//Split number into 2 uint8 numbers
		var uint8 = new Uint8Array(2);
		uint8[0] = value & 0xff;
		uint8[1] = (value >> 8) & 0xff;
		this.sendValue(this.irThresholdService, uint8);
	}

	public initFallbackFromUser(user:IUser):void {
		this.emulateReedTop = user.emulateReedTop;
		this.emulateReedRight = user.emulateReedRight;
		this.emulateReedBack = user.emulateReedBack;
		this.emulateIrFront = user.emulateIrFront;
		this.emulateIrBack = user.emulateIrBack;
		this.emulateMotor = user.emulateMotor;
		this.emulateSound = user.emulateSound;
	}

	/**
	 * Used by admin
	 */
	public disableFallbackFeatures():void {
		this.emulateReedTop = false;
		this.emulateReedRight = false;
		this.emulateReedBack = false;
		this.emulateIrFront = false;
		this.emulateIrBack = false;
		this.emulateMotor =false;
		this.emulateSound =false;
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private initialize():void {
		//@ts-ignore
		this.ble = navigator.bluetooth;
		
		//Make public props reactive
		Vue.observable(BleDeviceHelper.instance);
	}

	/**
	 * Send a value to the box
	 * 
	 * @param serviceID 
	 * @param value 
	 */
	private async sendValue(serviceID:string, value:any):Promise<void> {
		this.callStack.push({service:serviceID, value:value});
		if(!this.connected) return Promise.resolve();
		return this.executeNextCommand();
	}

	private async executeNextCommand():Promise<any> {
		if(this.executingACommand) return;
		if(this.callStack.length == 0) return Promise.resolve();
		this.executingACommand = true;
		let service = this.callStack[0].service;
		let value = this.callStack[0].value;
		var characteristic = await this.service.getCharacteristic(service);
		var v = (typeof value == "object")? value : Uint8Array.of(value);
		console.log("Execute next command", service, value);
		return characteristic.writeValue(v).then(_=> {
			this.callStack.shift();
			this.executingACommand = false;
			if(this.callStack.length>0) {
				setTimeout(_=> {
					this.executeNextCommand();
				}, 150)
			}
		}).catch((e)=> {
			// console.error("Command failed :", service, value);
			// console.error(e);
			this.executingACommand = false;
			//Try again
			setTimeout(_=> {
				this.executeNextCommand();
			}, 100);
		});
	}

	/**
	 * Read a value from the box
	 * @param serviceID 
	 */
	private async readValue(serviceID:string):Promise<any> {
		var c = await this.service.getCharacteristic(serviceID)
		var v = await c.readValue();
		return v;
	}

	/**
	 * Subscribe to notifications
	 * @param serviceID 
	 * @param id 
	 */
	private async getNotifications(serviceID:string, id:string):Promise<void> {
		var characteristic = await this.service.getCharacteristic(serviceID);
		this.characteristics.push(characteristic);
		await characteristic.startNotifications();
		characteristic.addEventListener('characteristicvaluechanged', (event) => {
			var value = characteristic.value.getUint8(0);
			console.log("New "+id+" value :", value.toString());
			switch(id) {
				case "top": if(!this.emulateReedTop) this.topValue = value == 1; break;
				case "back": if(!this.emulateReedBack) this.backValue = value == 1; break;
				case "right": if(!this.emulateReedRight) this.rightValue = value == 1; break;
				case "irFront": if(!this.emulateIrFront) this.irFrontValue = value; break;
				case "irBack": if(!this.emulateIrBack) this.irBackValue = value; break;
			}
			this.dispatchEvent(new BleEvent(BleEvent.UPDATE_STATE, id, value));
		})
	}
}

export class BleEvent extends Event {
	public value:any;
	public prop:string;

	public static OPEN_CONNECTION_MODAL:string = "OPEN_CONNECTION_MODAL";
	public static START_CONNECTING:string = "START_CONNECTING";
	public static DEVICE_FOUND:string = "DEVICE_FOUND";
	public static SERVER_FOUND:string = "SERVER_FOUND";
	public static DEVICE_CONNECTED:string = "DEVICE_CONNECTED";
	public static CONNECT_ERROR:string = "CONNECT_ERROR";
	public static CONNECT_CANCELED:string = "CONNECT_CANCELED";

	public static DEVICE_LOST:string = "DEVICE_LOST";
	public static UPDATE_STATE:string = "UPDATE_STATE";

	constructor(type:string, prop:string = null, value:any = null) {
		super(type, null)
		this.value = value;
		this.prop = prop;
	}
}

export class SoundEffect {
	public static TELEPORT:number = 1;
	public static OPEN_BOX:number = 2;
	public static INSERT_KEY:number = 3;
	public static MAGNET_ENABLED:number = 4;
	public static TALKIE_ENABLED:number = 5;
	public static TALKIE_ORGANIC:number = 14;
	public static BLUETOOTH_CONNECTED:number = 6;
	public static BLUETOOTH_ORGANIC:number = 13;
	public static BACK_HOME:number = 7;
	public static GLITCHING:number = 8;
	public static FREQUENCY_AMBIANT:number = 9;
	public static NAME:number = 10;
	public static AMBIANT_MURMURS:number = 11;
	public static AMBIANT_ONLY:number = 12;
}