import { Event } from './EventDispatcher';

/**
* Created : 07/12/2020 
*/
export default class LetterEvent extends Event {
	
	constructor(type:string, public index:number, public effect:string) {
		super(type, null);
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
}