













import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class MicAccessStatus extends Vue {

	public label:string = null;
	public showEnableHint:boolean = false;

	public mounted():void {
		this.onMicAccessChanged();
	}

	public beforeDestroy():void {
		
	}

	@Watch("$store.state.micAccessRefusedReason")
	public onMicAccessChanged():void {
		let code = this.$store.state.micAccessRefusedReason;
		if(!code) {
			this.label = null;
			return;
		}
		code = code.toLowerCase();
		this.label = code;
		// switch(code) {
		// 	case "aborterror":
		// 		break;
		// 	case "notallowederror":
		// 		break;
		// 	case "notfounderror":
		// 		break;
		// 	case "notreadableerror":
		// 		break;
		// 	case "overconstrainederror":
		// 		break;
		// 	case "securityerror":
		// 		break;
		// 	case "typeerror":
		// 		break;
		// }
		this.showEnableHint = true;
	}

	private close():void {
		this.$store.dispatch("setMicAccessRefusedReason", null);
	}

}
