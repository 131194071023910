












import Utils from '@/utils/Utils';
import gsap from 'gsap';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class CirclesRenderer extends Vue {

	@Prop({default:20})
	public sizeOffset:number;

	@Prop({default:3})
	public circles:number;

	@Prop({default:0})
	public skipEvery:number;

	@Prop({default:6})
	public step:number;

	@Prop({default:2})
	public thickness:number;

	@Prop({default:0})
	public angleOffset:number;

	@Prop({default:1})
	public animSpeedRatio:number;

	@Prop({default:true})
	public animate:boolean;

	@Prop({default:false})
	public showDashes:boolean;

	@Prop({default:false})
	public onlyHighlight:boolean;

	@Prop()
	public spectrumData:number[];

	public get styles():any {
		let size:number = (this.step*2 + this.thickness*2) * this.circles + (this.sizeOffset-this.thickness)*.5;
		return {
			width:size+"px",
			height:size+"px",
		}
	}

	public getCircleStyles(index:number, highlight:boolean = false):any {
		let size:number = (this.step*2 + this.thickness*2) * (index-1) + (this.sizeOffset);
		let maxSize:number = (this.step*2 + this.thickness*2) * this.circles + (this.sizeOffset-this.thickness)*.5;
		// let maskOffset =  Math.random() * Math.sqrt(size * .5) + 5;
		let angle =  Math.random() * 150 + 30;
		if(this.spectrumData) {
			if(this.spectrumData.length >= index) {
				angle = this.spectrumData[index-1];
			}else{
				angle = 0;
			}
		}
		if(this.skipEvery > 0) {
			size += Math.floor((index-1)/this.skipEvery) * (this.step*2 + this.thickness*2);
		}
		
		let pos = Math.floor((maxSize - size)*.5);
		let res:any = {
			width:size+"px",
			height:size+"px",
			top:pos+"px",
			left:pos+"px",
			borderWidth:this.thickness+"px",
		}


		if(highlight) {
			let list = [];
			let sides = 4;
			let size = 100;
			let angleOffset = (this.angleOffset + 90 - angle*.5)/360 * Math.PI*2;//Center angle to top
			// angleOffset += this.angleOffset;//(-90 - angle*.5)/360 * Math.PI*2;//center angle to bottom
			//Create radial mask
			list.push("50% 50%");
			for (let i = 0; i < sides; i++) {
				let rad = angle/360 * Math.PI*2 *i/(sides-1);
				let px:string = (50 - Math.cos(rad + angleOffset) * size).toFixed(1);
				let py:string = (50 - Math.sin(rad + angleOffset) * size).toFixed(1);
				list.push(px+"% "+py+"%");
			}
			res.clipPath = "polygon("+list.join(',')+")";
		}

		return res;
	}

	public mounted():void {
		if(this.animate) {
			let circles = <Element[]>this.$refs.circle;
			for (let i = 0; i < circles.length; i++) {
				const c = circles[i];
				let a = Math.random() * 360;
				gsap.set(c, {rotate:a})
				//Shit slow on mobile, only animate if there are very few circles (like loaders)
				if(circles.length <= 5) {
					gsap.to(c, (Math.random()*.25 + .3) * this.animSpeedRatio, {force3D: false, rotate:a+(Math.random() > .5? 1: -1)*360, ease:"linear", yoyo:Math.random() > .5, repeat:-1})
				}
			}
		}
		if(this.showDashes !== false) {
			this.drawDashes();
		}
	}

	public beforeDestroy():void {
		let circles = <Element[]>this.$refs.circle;
		for (let i = 0; i < circles.length; i++) {
			gsap.killTweensOf(circles[i]);
		}
	}

	@Watch("spectrumData", {deep:true, immediate:true})
	public onSpectrumDataChange():void {
		
	}

	private drawDashes():void {
		let canvas = <HTMLCanvasElement>this.$refs.canvas;
		let bounds = canvas.getBoundingClientRect();
		canvas.width = bounds.width;
		canvas.height = bounds.height;
		let ctx = canvas.getContext("2d");
		ctx.clearRect(0,0,canvas.width,canvas.height);
		let offset = this.sizeOffset / 2;
		let px = Math.round(canvas.width * .5);
		let py = Math.round(canvas.height * .5);
		let skip = this.skipEvery;

		ctx.beginPath();
		ctx.strokeStyle = Utils.getLessVars().mainColor_normal;
		ctx.lineWidth = 1;
		for (let i = 0; i < this.circles; i++) {
			ctx.moveTo(px, py - offset - 1);
			ctx.lineTo(px, py - offset + 1 + this.thickness);
			ctx.stroke();
			
			ctx.moveTo(px, py + offset + 1);
			ctx.lineTo(px, py + offset - 1 - this.thickness);
			ctx.stroke();
			
			offset += this.step + this.thickness;
			if(i > 0 && (i+1)%skip == 0) {
				offset += this.step + this.thickness;
			}
		}
		ctx.closePath();
	}

}
