import store from '@/store';
import Config from './Config';
import Utils from './Utils';

/**
* Created : 19/09/2020 
*/
export default class Peer2peerHelper {

	private static _instance:Peer2peerHelper;
	private peer:Peer;

	private remotePeerId:string;
	private remotePeer:DataConnection;
	
	constructor() {
	
	}
	
	/********************
	* GETTER / SETTERS *
	********************/
	static get instance():Peer2peerHelper {
		if(!Peer2peerHelper._instance) {
			Peer2peerHelper._instance = new Peer2peerHelper();
			Peer2peerHelper._instance.initialize();
		}
		return Peer2peerHelper._instance;
	}
	
	
	
	/******************
	* PUBLIC METHODS *
	******************/
	public connect(myPeerId?:string, remotePeerId?:string):Promise<void> {
		if(this.peer) return Promise.resolve();
		
		if(!myPeerId) myPeerId = store.state.user._id;
		if(remotePeerId) this.remotePeerId = remotePeerId;
		if(!this.remotePeerId) this.remotePeerId = Utils.getTeamMate()._id;
		
		console.log("CONNECT", myPeerId, this.remotePeerId);

		return new Promise((resolve, reject) => {
			console.log("CONNECT WITH ID ", myPeerId, "...");
			this.peer = new Peer(myPeerId, {
				host:document.location.hostname,
				port:Config.SERVER_PORT,
				path:"peerjs/theartifactp2p",
			});
			
			this.remotePeer = this.peer.connect(this.remotePeerId);
			this.peer.on("open", (id)=> {
				console.log("Connected with ID ", id);
				resolve();
			})
			this.peer.on("connection", (conn)=> {
				console.log("new connection", conn);
				resolve();
			})
			this.remotePeer.on("data", (data)=> {
				console.log("Data received ", data)
				resolve();
			})
			this.peer.on("error", (data)=> {
				console.log("Error ", data);
				resolve();
			});
		})
	}
	
	public disconnect():void {
		this.peer.disconnect();
		this.peer = null;
	}

	public call(stream:MediaStream):MediaConnection {
		console.log("CALL remote peer ID : ", this.remotePeerId);
		return this.peer.call(this.remotePeerId, stream);
	}
	
	//Unable to make this work...
	// public send(id:string):void {
	// 	console.log("Send to remote", id);
	// 	console.log(this.remotePeer)
	// 	this.remotePeer.send({action:id});
	// }

	public on(event:"open" | "data" | "connection" | "call" | "close" | "disconnected" | "error", callback:(conn:MediaConnection)=>void):void {
		this.peer.on(event, callback);
	}
	
	
	
	/*******************
	* PRIVATE METHODS *
	*******************/
	private initialize():void {

	}
}

export declare class Peer {

	id:string;
	connections:any;
	disconnected:boolean;
	destroyed:boolean;

    constructor(id: string, init: {
		host?:string,
		port?:number,
		path?:string,
		key?:string,
		pingInterval?:number,
		secure?:boolean,
		config?:any,
		debug?:number,
	});

	connect(id:string, options?:{label:string, metadata:any, serialization:string, reliable:boolean}):DataConnection;
	call(id:string, stream:MediaStream, options?:{metadata:any}):MediaConnection;
	on(event:"open" | "data" | "connection" | "call" | "close" | "disconnected" | "error", callback:(conn:MediaConnection)=>void):void;
	send(id:string):void;
	disconnect():void;
	reconnect():void;
	destroy():void;
}

export interface DataConnection {

	dataChannel:any;
	label:string;
	metadata:any;
	open:boolean;
	peerConnection:any;
	peer:string;
	reliable:boolean;
	serialization:string;
	type:string;
	bufferSize:number;

	send(data:any):void;
	close():void;
	on(event:"data" | "open" | "close" | "error", callback:(data:any)=>void):void;
}

export interface MediaConnection {

	metadata:any;
	open:boolean;
	peer:string;
	type:string;

	answer(stream:MediaStream, options?:any):void;
	close():void;
	on(event:"stream" | "close" | "error", callback:(data:any)=>void):void;
}