







import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import * as THREE from "three";
import Config from '@/utils/Config';
import { Vector3 } from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {BloomPass} from 'three/examples/jsm/postprocessing/BloomPass';
import {FilmPass} from 'three/examples/jsm/postprocessing/FilmPass';
import {BokehPass} from 'three/examples/jsm/postprocessing/BokehPass';
import {MaskPass} from 'three/examples/jsm/postprocessing/MaskPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {RectAreaLightHelper} from 'three/examples/jsm/helpers/RectAreaLightHelper';
import {RectAreaLightUniformsLib} from 'three/examples/jsm/lights/RectAreaLightUniformsLib';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {GlitchPass} from '@/utils/GlitchPass';


@Component({
	components:{}
})
export default class Box3D extends Vue {

	@Prop()
	public reveal:boolean;
	
	private focus:number = 80;
	private aperture:number = 80;
	private cameraDistance:number = 60;

	private _disposed: boolean;
	private _dragging: boolean;
	private _scene: THREE.Scene;
	private _camera: THREE.PerspectiveCamera;
	private _renderer: THREE.WebGLRenderer;
	private _canvas:HTMLCanvasElement;
	private _cubeGeom: THREE.BoxGeometry;
	private _cubeBlack: THREE.Mesh;
	private _cubeWhite: THREE.Mesh;
	private _objGroup: THREE.Group;
	private _spotlight: THREE.SpotLight;
	private _directionalLight: THREE.DirectionalLight;
	private _composer: EffectComposer;
	private _clippingPlanes: THREE.Plane[];
	private _bokehPass: BokehPass;
	private _bloomPass: UnrealBloomPass;
	private _glitchPass: GlitchPass;
	private _filmPass: FilmPass;
	private _increment: number = 0;
	private _clipAngle: number = 0;
	private _bloomStrength: number = 0;
	private _bokehGlitchInc: number = 0;
	private _mouseMoveHandler: any;
	private _mouseDownHandler: any;
	private _mouseUpHandler: any;
	private _mousePos: {x:number, y:number};
	private _dragStart: {x:number, y:number};
	private _cameraAngle: number;
	private _boxAngle: number;
	private _showLights: THREE.Light[];

	public mounted():void {
		this._clipAngle = 0;
		this._bloomStrength = 0;
		this._bloomStrength = 0;
		this._cameraAngle = 0;
		this._increment = 0;
		this._boxAngle = -Math.PI/2;
		this._showLights = [];
		this._mousePos = {x:0, y:window.innerHeight/2};
		this.createScene();
		this.createBox();
		this.createLights();
		this.createComposer();
		this.renderWebgl();

		this._mouseMoveHandler = (e:MouseEvent|TouchEvent) => this.onMouseMove(e);
		this._mouseDownHandler = (e:MouseEvent|TouchEvent) => this.onMouseDown(e);
		this._mouseUpHandler = (e:MouseEvent|TouchEvent) => this.onMouseUp(e);
		document.addEventListener("mousedown", this._mouseDownHandler);
		document.addEventListener("touchstart", this._mouseDownHandler);
		document.addEventListener("mouseup", this._mouseUpHandler);
		document.addEventListener("touchend", this._mouseUpHandler);
		document.addEventListener("mousemove", this._mouseMoveHandler);
		document.addEventListener("touchmove", this._mouseMoveHandler);
		this.onRevealChange();

		this.$emit("ready");
	}

	public beforeDestroy():void {
		this.$el.removeChild(this._renderer.domElement);
		this._disposed = true;
		this._scene.traverse((object: any) => {
			if (!object.isMesh) return
			object.geometry.dispose()
			if (object.material.isMaterial) {
				this.cleanMaterial(object.material)
			} else {
				// an array of materials
				for (const material of object.material) this.cleanMaterial(material)
			}
		});

		this._renderer.dispose();
		
		//Cleanup renderer completely, dispose() doesn't do all the job
		this._renderer.forceContextLoss();
		// this._renderer.context = null;
		this._renderer.domElement = null;
		this._renderer = null;
		document.removeEventListener("mousedown", this._mouseDownHandler);
		document.removeEventListener("touchstart", this._mouseDownHandler);
		document.removeEventListener("mouseup", this._mouseUpHandler);
		document.removeEventListener("touchend", this._mouseUpHandler);
		document.removeEventListener("mousemove", this._mouseMoveHandler);
		document.removeEventListener("touchmove", this._mouseMoveHandler);
	}

	@Watch("reveal")
	private onRevealChange():void {
		if(this.reveal) {
			this._bokehPass.enabled = false;
			this._bloomPass.enabled = false;
			this._glitchPass.enabled = false;
			// this._filmPass.enabled = false;
			(<any>this._filmPass.uniforms).grayscale.value = 0;
			(<any>this._filmPass.uniforms).sIntensity.value = 0;
			for (let i = 0; i < this._showLights.length; i++) {
				this._scene.add(this._showLights[i]);
			}
			let materials = <THREE.MeshStandardMaterial[]>this._cubeBlack.material;
			for (let i = 0; i < materials.length; i++) {
				const m = materials[i];
				m.metalness = 0;
			}
			materials = <THREE.MeshStandardMaterial[]>this._cubeWhite.material;
			for (let i = 0; i < materials.length; i++) {
				const m = materials[i];
				m.metalness = 0;
			}
		}else{
			this._mousePos = {x:0, y:window.innerHeight/2};
			this._bokehPass.enabled = true;
			this._bloomPass.enabled = true;
			this._glitchPass.enabled = true;
			this._filmPass.enabled = true;
			(<any>this._filmPass.uniforms).grayscale.value = 1;
			(<any>this._filmPass.uniforms).sIntensity.value = .25;
			for (let i = 0; i < this._showLights.length; i++) {
				this._scene.remove(this._showLights[i]);
			}
			let materials = <THREE.MeshStandardMaterial[]>this._cubeBlack.material;
			for (let i = 0; i < materials.length; i++) {
				const m = materials[i];
				m.metalness = .5;
			}
			materials = <THREE.MeshStandardMaterial[]>this._cubeWhite.material;
			for (let i = 0; i < materials.length; i++) {
				const m = materials[i];
				m.metalness = .5;
			}
		}
	}

	private eventToPos(e:MouseEvent|TouchEvent):{x:number, y:number} {
		let x = (e instanceof MouseEvent)? e.clientX : (<TouchEvent>e).touches[0].clientX;
		let y = (e instanceof MouseEvent)? e.clientY : (<TouchEvent>e).touches[0].clientY;
		return {x,y}
	}

	private onMouseMove(e:MouseEvent|TouchEvent):void {
		if(!this.reveal) return;
		this._mousePos = this.eventToPos(e);
	}

	private onMouseDown(e:MouseEvent|TouchEvent):void {
		if(!this.reveal) return;
		this._dragging = true;
		this._dragStart = this.eventToPos(e);
		this._mousePos = this.eventToPos(e);
	}

	private onMouseUp(e:MouseEvent|TouchEvent):void {
		if(!this.reveal) return;
		this._dragging = false;
	}

	/**
	 * Cleans up a material data from memory
	 * 
	 * @param material 
	 */
	private cleanMaterial(material: THREE.Material) {
		material.dispose();

		for (const key of Object.keys(material)) {
			const value = material[key]
			if (value && typeof value === 'object' && 'minFilter' in value) {
				value.dispose();
			}
		}
	}

	private createScene():void {
		let width = window.innerWidth;
		let height = window.innerHeight;

		this._scene = new THREE.Scene()
		this._camera = new THREE.PerspectiveCamera(90, width/height, 1, 1000);
		this._camera.position.z = this.cameraDistance;
		this._renderer = new THREE.WebGLRenderer({ alpha: false, antialias:true });
		this._renderer.setSize(width, height);
		this._renderer.setPixelRatio(window.devicePixelRatio);
		this._canvas = this._renderer.domElement;
		this.$el.appendChild(this._canvas);
	}

	private createComposer():void {
		this._composer = new EffectComposer(this._renderer);
		this._composer.addPass(new RenderPass(this._scene, this._camera));
		let width = window.innerWidth;
		let height = window.innerHeight;
    	this._composer.setSize(width, height);
		
		var bokehSettings = {
			focus : 41, aperture : .0016,  maxblur : .01,
			// farClip: 100,
			// nearClip: 20,
			width, height
		}
		this._bokehPass =  new BokehPass(this._scene, this._camera, bokehSettings );
		this._bokehPass.needsSwap = true;

		this._glitchPass = new GlitchPass();

		this._bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
		this._bloomPass.threshold = .15;
		this._bloomPass.strength = 1;
		this._bloomPass.radius = .5;

		this._filmPass = new FilmPass(
			.5,   // noise intensity
			.25,  // scanline intensity
			1000,    // scanline count
			1,
		);
		this._filmPass.renderToScreen = true;

		this._composer.addPass(this._bokehPass);
		this._composer.addPass(this._bloomPass);
		this._composer.addPass(this._glitchPass);
		this._composer.addPass(this._filmPass);
	}

	private createLights():void {
		
		RectAreaLightUniformsLib.init();
		const rectLight = new THREE.RectAreaLight( 0xffffff, 1, 100, 10 );
		rectLight.position.set( 10, 0, 30 );
		rectLight.rotateZ(Math.PI/1.5)
		this._scene.add( rectLight );

		// const geometry = new THREE.SphereGeometry( 30, 32, 32 );
		// const material = new THREE.MeshStandardMaterial( {color: 0xffffff} );
		// const sphere = new THREE.Mesh( geometry, material );
		// this._scene.add( sphere );

		this._scene.add(new THREE.AmbientLight(0xffffff, .1));

		let pl = new THREE.PointLight(0xffffff, .1);
		pl.position.x = 0;
		pl.position.y = 70;
		pl.position.z = 0;
		// this._scene.add(pl);
		this._showLights.push(pl);

		pl = new THREE.PointLight(0xffffff, .1);
		pl.position.x = 0;
		pl.position.y = -70;
		pl.position.z = 0;
		// this._scene.add(pl);
		this._showLights.push(pl);
		
		this._spotlight = new THREE.SpotLight(0xffffff, 1);
		this._spotlight.angle = Math.PI;
		this._spotlight.decay = 1;
		this._spotlight.target = this._cubeBlack;
		this._spotlight.position.x = 10;
		this._spotlight.position.y = 0;
		this._spotlight.position.z = 50;
		this._showLights.push(this._spotlight);
		// this._scene.add(this._spotlight);
	}

	private createBox():void {
		let sideRatio = Config.BOX_WIDTH/Config.BOX_HEIGHT;
		let cubeH = 50;
		let cubeW = cubeH*sideRatio;
		this._clippingPlanes = [
			new THREE.Plane( new THREE.Vector3( -1, .25, 0 ), 1 ),
			new THREE.Plane( new THREE.Vector3( 1, -.25, 0 ), 1 )
		]

		this._cubeGeom = new THREE.BoxGeometry(cubeW, cubeH, cubeW, 1,1,1);
		this._cubeWhite = new THREE.Mesh(this._cubeGeom, this.createCubeMaterial("white"));
		this._cubeBlack = new THREE.Mesh(this._cubeGeom, this.createCubeMaterial("black"));
		this._objGroup = new THREE.Group();
		this._objGroup.add(this._cubeBlack);
		this._objGroup.add(this._cubeWhite);
		this._scene.add(this._objGroup);
		this._renderer.localClippingEnabled = true;
		this._objGroup.rotateY(Math.PI*1.4);
		
		let hinge = require('@/assets/3Dbox/hinge.obj');
		let loader = new OBJLoader();
		loader.load(hinge.default, (hinge1)=> {
			hinge1.traverse( ( child ) => {
				if(child instanceof THREE.Mesh) {
					child.material.color.setHex(0xbca848);
				}
			} );
			hinge1.position.x = -cubeW*.5 + .05;
			hinge1.position.y = cubeH*1/4;
			hinge1.position.z = cubeW*.5 - 2.3;
			hinge1.rotateZ(Math.PI/2);
			let s = .07;
			hinge1.scale.set(s,s,s);
			this._objGroup.add(hinge1);

			let hinge2 = hinge1.clone();
			hinge2.position.y = -cubeH*1/4;
			this._objGroup.add(hinge2);
		})
	}

	private createCubeMaterial(color:string):THREE.MeshStandardMaterial[] {
		// let specular = new THREE.Color(0xffffff);
		let lightMapIntensity = 1;
		let metalness = .5;
		let roughness = .2;
		let normalScale = new THREE.Vector2(1,-1);
		let clippingPlanes = [color == "white"? this._clippingPlanes[0] : this._clippingPlanes[1]];

		//Generate normal_lightmaps via this tool :
		// https://cpetry.github.io/NormalMap-Online/
		// values :
		//   strength : 5
		//   level : 10
		//   blur/sharp : 0
		//   filter : sobel
		//   Invert : -none-
		var material = [
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/right_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/right_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/right_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// // specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/right_lightmap.jpg')),
				// // specular,
				clippingPlanes,
			}),
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/left_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/left_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/left_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/left_lightmap.jpg')),
				// specular,
				clippingPlanes,
			}),
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/top_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/top_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/top_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/top_lightmap.jpg')),
				// specular,
				clippingPlanes,
			}),
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/bottom_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/bottom_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/bottom_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/bottom_lightmap.jpg')),
				// specular,
				clippingPlanes,
			}),
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/front_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/front_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/front_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/front_lightmap.jpg')),
				// specular,
				clippingPlanes,
			}),
			new THREE.MeshStandardMaterial({
				map: new THREE.TextureLoader().load(require('@/assets/3Dbox/back_'+color+'.jpg')),
				lightMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/back_lightmap.jpg')),
				lightMapIntensity,
				normalMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/back_map.jpg')),
				normalScale,
				metalness,
				roughness,
				// specularMap: new THREE.TextureLoader().load(require('@/assets/3Dbox/back_lightmap.jpg')),
				// specular,
				clippingPlanes,
			}),
		];
		

		//Disable mipmaps & stuff to avoid "non power of 2 texture size blabla"
		// for (let i = 0; i < material.length; i++) {
		// 	const m = material[i];
			// m.map.generateMipmaps = false;
			// m.map.wrapS = m.map.wrapT = THREE.ClampToEdgeWrapping;
			// m.map.minFilter = THREE.LinearFilter;
			// m.opacity = .75;
			// m.premultipliedAlpha = true;
			
			// if(m.lightMap) {
			// 	m.lightMap.generateMipmaps = false;
			// 	m.lightMap.wrapS = m.lightMap.wrapT = THREE.ClampToEdgeWrapping;
			// 	m.lightMap.minFilter = THREE.LinearFilter;
			// }
			
			// if(m.displacementMap) {
			// 	m.displacementMap.generateMipmaps = false;
			// 	m.displacementMap.wrapS = m.displacementMap.wrapT = THREE.ClampToEdgeWrapping;
			// 	m.displacementMap.minFilter = THREE.LinearFilter;
			// }
			
			// if(m.specularMap) {
			// 	m.specularMap.generateMipmaps = false;
			// 	m.specularMap.wrapS = m.specularMap.wrapT = THREE.ClampToEdgeWrapping;
			// 	m.specularMap.minFilter = THREE.LinearFilter;
			// }
		// }
		return material;
	}

	/**
	 * Main render loop
	 */
	private renderWebgl(): void {
		if (this._disposed) return;
		requestAnimationFrame(() => this.renderWebgl());
		
		this._clipAngle += 0.005;
		this._bloomStrength += 0.045;
		this._increment ++;
		if(this._increment == 200) {
			this.$emit("introComplete");
		}

		// console.log(this._filmPass.uniforms);
		// console.log((<any>this._filmPass.uniforms).noiseIntensity);
		(<any>this._filmPass.uniforms).nIntensity.value = (Math.cos(this._bloomStrength) + 1)/2 * .5 + .5;
		(<any>this._filmPass.uniforms).sCount.value = 300 + Math.cos(this._bloomStrength*30) * 150;

		// (<any>this._bokehPass.uniforms).focus.value = this.focus;
		// (<any>this._bokehPass.uniforms).aperture.value = this.aperture*.0001;
		// console.log(this.aperture*.0001)
		// this._bokehGlitchInc = (++this._bokehGlitchInc)%180;
		// this._bokehPass.enabled = Math.random() > .5 || this._bokehGlitchInc < 100;

		this._bloomPass.strength = (Math.cos(this._bloomStrength) + 1)/2 * .1 + .25;

		// let px = this._bokehGlitchInc < 150? 0 : (Math.random()-Math.random())*.5;
		// let py = this._bokehGlitchInc < 150? 0 : (Math.random()-Math.random())*.5;
		// this._cubeWhite.position.setX(px);
		// this._cubeBlack.position.setX(px);
		// this._cubeWhite.position.setY(py);
		// this._cubeBlack.position.setY(py);

		let a = Math.sin(this._clipAngle)*2;
		let n1 = new THREE.Vector3( -1, a, 0 );
		let n2 = new THREE.Vector3( 1, -a, 0 );
		this._clippingPlanes[0].normal = n1;
		this._clippingPlanes[1].normal = n2;

		let ratio = .01;

		if(this.reveal) {
			ratio = .04;
			if(this._dragging) {
				this._boxAngle += (this._mousePos.x - this._dragStart.x)*.0075;
				this._cameraAngle -= (this._mousePos.y - this._dragStart.y)*.0075;
				this._cameraAngle = Math.min(Math.max(0, this._cameraAngle), Math.PI)
				this._dragStart = this._mousePos;
			}
			// let angle = (this._mousePos.x / window.innerWidth-.5) * Math.PI*2;
			// this._boxAngle += (angle - this._boxAngle) * ratio;
		}else{
			this._boxAngle += .01;
			let targetAngle = Math.PI/3;
			this._cameraAngle += (targetAngle - this._cameraAngle) * ratio;
		}
		this._boxAngle = this._boxAngle % (Math.PI*2);

		this._objGroup.setRotationFromAxisAngle(new Vector3(0,1,0), this._boxAngle);
		this._camera.position.setY(Math.cos(this._cameraAngle) * this.cameraDistance);
		this._camera.position.setZ(Math.sin(this._cameraAngle) * this.cameraDistance);
		this._camera.lookAt(this._cubeBlack.position);
		this._composer.render();
		// this._renderer.render(this._scene, this._camera);
	}

}
