





























































import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import Button from '@/components/Button.vue';
import MessageWritter from '@/components/MessageWritter.vue';
import Api from '@/utils/Api';
import Config from '@/utils/Config';
import Utils from '@/utils/Utils';
import gsap from 'gsap';
import IUser from 'src_back/db/models/user/IUser';
import CloseInstructions from './CloseInstructions.vue';

@Component({
	components:{
		Button,
		MessageWritter,
		CloseInstructions,
	}
})
export default class GameEnd extends Vue {

	public message:string = "";
	public firstBoxDuration:string = "";
	public secondBoxDuration:string = "";
	public waitDuration:string = "";
	public collabDuration:string = "";
	public diskDuration:string = "";
	public hintsCount:number = 0;
	public total:string = "";
	public shanimCompleteowStats:boolean = false;
	public animComplete:boolean = false;
	public showStats:boolean = false;
	public showClose:boolean = false;
	public restarting:boolean = false;

	public async mounted():Promise<void> {
		let u:IUser;
		try {
			u = await Api.get("user", {uid:this.$store.state.user._id});
		}catch(e) {
			u = this.$store.state.user;//Fallback to probably incomplete storage :(
		}
		this.firstBoxDuration = Utils.durationToText( new Date(u.box1Opened_at).getTime() - new Date(u.start_at).getTime(), true );
		this.secondBoxDuration = Utils.durationToText( new Date(u.key1Inserted_at).getTime() - new Date(u.box1Opened_at).getTime(), true );
		this.waitDuration = Utils.durationToText( new Date(u.collaborationStarted_at).getTime() - new Date(u.key1Inserted_at).getTime(), false );
		this.collabDuration = Utils.durationToText( new Date(u.cubesPlaced_at).getTime() - new Date(u.collaborationStarted_at).getTime(), true );
		this.diskDuration = Utils.durationToText( new Date(u.discInserted_at).getTime() - new Date(u.cubesPlaced_at).getTime(), true );
		this.total = Utils.durationToText( new Date(u.box1Opened_at).getTime() - new Date(u.start_at).getTime()
					+	new Date(u.key1Inserted_at).getTime() - new Date(u.box1Opened_at).getTime()
					+	new Date(u.cubesPlaced_at).getTime() - new Date(u.collaborationStarted_at).getTime()
					+	new Date(u.discInserted_at).getTime() - new Date(u.cubesPlaced_at).getTime(), true );
		this.hintsCount = u.hintsReadStates? Object.keys(u.hintsReadStates).length : 0;
	}

	public beforeDestroy():void {
		
	}

	public fastForward():void {
		(<MessageWritter>this.$refs.writter).skipAnimation();
	}

	private fadeEnter(el, done):void {
		Utils.showTransition(<HTMLDivElement>this.$refs.stats, .5, .65, -15);
		Utils.showTransition(<HTMLDivElement>this.$refs.rows, .5, .65, -15);
		setTimeout(_=> {
			done()
		}, 1000);
	}

	private fadeBeforeLeave(el):void {
		Utils.hideTransition(<HTMLDivElement>this.$refs.stats, .3);
		Utils.hideTransition(<HTMLDivElement>this.$refs.rows, .3);
	}

	private restart():void {
		Utils.confirm(this.$t('game.end.restartConfirm.title'), this.$t('game.end.restartConfirm.description'))
		.then(async _=> {
			this.restarting = true;
			let res;
			try {
				res = await Api.post("user/resetProgression", {pid:this.$store.state.user.privateID, gid:this.$store.state.game._id});
				if(!res.success) throw("update failed");
				let size = Math.max(document.body.clientWidth, document.body.clientHeight) * 1.5;
				gsap.to(this.$refs.resetCircle, 1, {width:size, height:size, ease:"sine.in"});
				gsap.to(this.$refs.resetCircle2, 1, {width:size, height:size, ease:"sine.in", delay:.5, onComplete:_=> {
					console.log(res.startKey);
					this.$router.push({name:"game:start", params:{pubkey:res.startKey, lang:this.$store.state.lang}});
				}});
			}catch(error) {
				if(!error.alertDone) this.$store.dispatch("alert", this.$t("errors.genericRequestError"));
			}
			this.restarting = false;
		}).catch(_=>{/*ignore*/})
	}

}
