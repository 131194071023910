


















import CirclesRendererCanvas from '@/components/CirclesRendererCanvas.vue';
import Api from '@/utils/Api';
import Utils from '@/utils/Utils';
import IUser from 'src_back/db/models/user/IUser';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{
		CirclesRendererCanvas,
	}
})
export default class GameStart extends Vue {
	
	// @Prop()
	public pubkey:string;
	
	public loading:boolean = true;
	public error:string = null;
	public step:string = null;
	public user:IUser = null;

	public get labelKey():string {
		if(this.step != null) return "greetingBack";
		return "greeting";
	}

	public async mounted():Promise<void> {
		
		let res;
		//Couldn't make @Prop() working with nested router-view -_-...
		this.pubkey = this.$route.params.pubkey;
		if(!this.pubkey) {
			this.loading = false;
			this.error = this.$t("errors.gameStart").toString();
			return;
		}
		try {
			res = await Api.post("game/start", {key:this.pubkey});
		}catch(error) {
			let message = this.$t("errors.gameStart").toString();
			// if(error.code) {
			// 	message += "<br><br>" + this.$t("error."+error.code);
			// }
			this.loading = false;
			this.error = message;
			return;
		}

		this.loading = false;
		this.user = res.user;
		this.step = this.user.currentStep;
		this.$store.dispatch("startGame", {user:res.user, game:res.game});

		await Utils.promisedTimeout(2500);

		this.user = null;
		
		await Utils.promisedTimeout(1000);
		
		if(this.step) {
			this.$router.push(this.step);
		}else{
			this.$router.push({name:"game:d", params:{step:'instructions', lang:this.$store.state.lang}});
		}
	}

	public beforeDestroy():void {
		
	}

}
