









import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
	components:{}
})
export default class Loading extends Vue {

	public mounted():void {
		
	}

	public beforeDestroy():void {
		
	}

}
